import { format, parseJSON } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { getLoadingSimplifiedLancamento, SimplifiedLancamento } from '../../../domain/lancamento/lancamento'
import {
  useLocalStorage,
  useNumericLocalStorage,
  useObjectLocalStorage
} from '../../../hooks/useLocalStorage'
import { useDeleteLancamentos } from '../../../queries/lancamento/mutations'
import { useFamilyLancamentos, UseFamilyLancamentosOptions } from '../../../queries/lancamento/queries'
import { noticeError } from '../../../services/Monitoring'
import { Button } from '../../Buttons'
import { useTableSelection } from '../hooks'
import Table from '../Table'
import { LancamentoEdit, useColumns } from './columns'
import Footer from './Footer'
import { useFamilyMeiosPagamentos } from '../../../queries/meioPagamento/queries'
import { useFamilyCategorias } from '../../../queries/categoria/queries'
import { useFamilyOrcamentos } from '../../../queries/orcamento/queries'
import { formatMonetaryValue } from '../../../domain/formatter'
import { useFamilyQuery } from '../../../queries/family/queries'
import { TipoOrcamento, isExpense } from '../../../domain/orcamento/Orcamento'
import { useToggleFamilyAutoCategorization } from '../../../queries/family/mutations'
import { ModalDeleteTransaction } from '../../Modal/DeleteTransaction'
import { Props, Sort } from './types'
import * as S from './styles'
import { ConfirmCategorization } from './ConfirmCategorization'
import { useTransactionContext } from '../../../hooks/contexts/transactionContext'

export const LancamentosTable = ({ familyId, start, end }: Props) => {
  const { setEditingEntry } = useTransactionContext()
  const { setValue } = useObjectLocalStorage<LancamentoEdit>('table_l_edits', {})
  const resetBeingEdited = useCallback(() => setValue({}), [setValue])
  const { meiosPagamentos: meios } = useFamilyMeiosPagamentos(familyId)
  const { categorias } = useFamilyCategorias(familyId)
  const { orcamentos } = useFamilyOrcamentos(familyId)
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage('lanc-page-size', 10)
  const [sort, setSort] = useState<Sort>()
  const { setValue: setStorageFilter, value: storageFilter } = useLocalStorage('filter')
  const { setValue: setStorageMethodId, value: storageMethodId } = useLocalStorage('methodId')
  const { setValue: setStorageBudgetId, value: storageBudgetId } = useLocalStorage('budgetId')
  const { setValue: setStorageType, value: storageType } = useLocalStorage('type')
  const { setValue: setStorageCategory, value: storageCategory } = useLocalStorage('category')
  const [filter, setFilter] = useState<string | undefined>(storageFilter || undefined)
  const [methodId, setPaymentMethodId] = useState<number | undefined>(Number(storageMethodId) || undefined)
  const [budgetId, setBudgetId] = useState<number | undefined>(Number(storageBudgetId) || undefined)
  const [type, setType] = useState<string | undefined>(storageType || undefined)
  const [categoryId, setCategoryId] = useState<number | undefined>(Number(storageCategory) || undefined)

  useEffect(() => {
    setFilter(storageFilter || undefined)
    setPaymentMethodId(Number(storageMethodId) || undefined)
    setBudgetId(Number(storageBudgetId) || undefined)
    setType(storageType || undefined)
    setCategoryId(Number(storageCategory) || undefined)
  }, [
    filter,
    setFilter,
    setPaymentMethodId,
    setBudgetId,
    setType,
    setCategoryId,
    storageFilter,
    storageMethodId,
    storageBudgetId,
    storageType,
    storageCategory
  ])

  const query: UseFamilyLancamentosOptions = {
    familyId,
    start,
    end,
    filter,
    page,
    size: pageSize,
    sort: sort?.prop,
    desc: sort?.desc,
    methodId,
    budgetId,
    categoryId,
    type
  }
  const {
    lancamentos,
    totalPages,
    isLancamentosError,
    isLoadingLancamentos,
    lancamentosError,
    refetchLancamentos
  } = useFamilyLancamentos(query, { enabled: familyId != null })
  const rows = lancamentos?.results || []

  const { selectedRows, select, selectAll, unselect, unselectAll } = useTableSelection(rows)

  const {
    isDeletingLancamentos,
    isLancamentosDeletionError,
    lancamentosDeletionError,
    resetLancamentosDeletion
  } = useDeleteLancamentos()

  const { family } = useFamilyQuery(familyId)
  const autoCategorizationNeverEnabled = family?.autoCategorizationDate === null
  const [confirmFirstCheck, setConfirmFirstCheck] = useState(false)
  const [confirmDisable, setConfirmDisable] = useState(false)
  const { toggleFamilyAutoCategorization } = useToggleFamilyAutoCategorization()
  const onDelete = useCallback(async () => setModalConfirmDelete(true), [])

  const { setValue: setValueOnOrcamentoCategorization, value: valueOnOrcamentoCategorization } =
    useLocalStorage('onselect-orcamento-categorization')

  const onSelectOrcamento = useCallback(() => {
    if (autoCategorizationNeverEnabled && valueOnOrcamentoCategorization !== 'true') {
      setValueOnOrcamentoCategorization('true')
      setConfirmFirstCheck(true)
    }
  }, [autoCategorizationNeverEnabled, setValueOnOrcamentoCategorization, valueOnOrcamentoCategorization])

  const columns = useColumns(selectedRows, unselectAll, onSelectOrcamento)
  const isLoading = isLoadingLancamentos || isDeletingLancamentos
  const loadingData = useMemo(getLoadingSimplifiedLancamento, [])

  const onSort = useCallback(
    (columnId: string, order: 'desc' | 'asc' | 'none') => {
      if (order === 'none') setSort({ desc: true, prop: 'Data' })
      else setSort({ desc: order === 'desc', prop: columnId })
    },
    [setSort]
  )

  const transformDownloadContent = (rows: SimplifiedLancamento[]) =>
    rows.map(
      ({
        data,
        descricao,
        valor,
        valorParcela,
        parcelado,
        parcelas,
        orcamentoId,
        categoriaId,
        meioPagamentoId,
        tipo
      }) => {
        const isParceled = parcelado && parcelas && valorParcela
        const amount: number = isParceled ? valorParcela ?? 0 : valor ?? 0
        const finalAmount = isExpense(tipo) ? -amount : amount

        const dataTable = {
          Data: new Date(data).toLocaleDateString('pt-BR'),
          Descrição: descricao,
          Valor: formatMonetaryValue(finalAmount, true),
          Orçamento: orcamentos?.find(({ id }) => id === orcamentoId)?.name || 'Sem orçamento',
          Categoria:
            (categorias?.length && categorias?.find(({ id }) => id === categoriaId)?.nome) || 'Sem categoria',
          'Meio de pagamento':
            meios?.find(({ id }) => id === meioPagamentoId)?.nome || 'Sem meio de pagamento'
        }

        if (isParceled) {
          dataTable['Parcelado'] = 'Sim'
        }

        if (tipo) {
          dataTable['Tipo'] = TipoOrcamento[tipo]
        }

        return dataTable
      }
    )

  const handleCloseFirstCheck = () => {
    setConfirmFirstCheck(false)
    setTimeout(() => window.location.reload(), 200)
  }

  const handleCloseDisable = () => {
    setConfirmDisable(false)
  }

  useEffect(() => unselectAll(), [unselectAll, pageSize, page])
  useEffect(() => setPage(0), [pageSize])
  useEffect(resetBeingEdited, [page, resetBeingEdited])

  // TODO: improve error handling
  if (isLancamentosError || isLancamentosDeletionError) {
    const error = lancamentosError || lancamentosDeletionError

    noticeError(error, { familyId, start, end })

    return (
      <S.ErrorContainer>
        <br />
        Erro ao carregar tabela :c <br />
        Tente novamente: <br />
        <Button
          text="Tentar novamente."
          onClick={() => {
            refetchLancamentos()
            resetLancamentosDeletion()
          }}
        />
      </S.ErrorContainer>
    )
  }

  const editLancamento = (lancamento: SimplifiedLancamento) => {
    const copy: SimplifiedLancamento & { tipoOrcamento?: TipoOrcamento } = { ...lancamento }
    copy.data = format(parseJSON(lancamento.data), 'dd/MM/yyyy')
    copy.tipoOrcamento = lancamento.tipo
    copy.tipoDeParcela = (copy?.parcelas ?? 0) > 1 ? copy?.tipoDeParcela : 99

    // @ts-expect-error
    setEditingEntry(copy)
  }

  return (
    <>
      <ConfirmCategorization
        handleCloseFirstCheck={handleCloseFirstCheck}
        handleCloseDisable={handleCloseDisable}
        confirmFirstCheck={confirmFirstCheck}
        setConfirmFirstCheck={setConfirmFirstCheck}
        confirmDisable={confirmDisable}
        setConfirmDisable={setConfirmDisable}
        toggleFamilyAutoCategorization={toggleFamilyAutoCategorization}
        familyId={familyId}
      />

      <Table
        title="Lançamentos"
        data={rows}
        columns={columns}
        isLoading={isLoading}
        mapDownloadFile={transformDownloadContent}
        downloadFormat="xlsx"
        loadingData={loadingData}
        toggledOffByDefaultColumns={['Tipo', 'Parcelado']}
        downloadFileName="Extrato"
        pageable={true}
        downloadable={true}
        pageIndex={page}
        pageSize={pageSize}
        totalPages={totalPages}
        onNextPage={() => setPage(page + 1)}
        onPreviousPage={() => setPage(page - 1)}
        onPageSizeChange={setPageSize}
        onRowClick={editLancamento}
        rowsSelectable={true}
        selectedRows={selectedRows}
        onRowSelection={select}
        onRowUnselection={unselect}
        onAllRowsSelection={selectAll}
        onAllRowsUnselection={unselectAll}
        deletable={selectedRows.length > 0}
        onDelete={onDelete}
        defaultFilter={filter}
        refetchData={refetchLancamentos}
        filterDefaultSelected={{
          Categorias: categoryId,
          'Meios de pagamento': methodId,
          Orçamentos: budgetId,
          'Tipo de orçamento': type
        }}
        onFilterChange={(filter?: string) => {
          setFilter(filter)
          setStorageFilter(filter || '')
        }}
        renderFooter={() => (lancamentos ? <Footer lancamentos={lancamentos} /> : <></>)}
        onSort={onSort}
        filterableColumnNames={['Categorias', 'Meios de pagamento', 'Orçamentos', 'Tipo de orçamento']}
        onColumnFilterChange={(column, value) => {
          const numberOrUndefined = Number(value) || undefined
          const stringOrUndefined = value?.toString() || undefined

          switch (column) {
            case 'Categorias':
              setCategoryId(numberOrUndefined)
              setStorageCategory(numberOrUndefined ? String(numberOrUndefined) : null)
              break
            case 'Meios de pagamento':
              setPaymentMethodId(numberOrUndefined)
              setStorageMethodId(numberOrUndefined ? String(numberOrUndefined) : null)
              break
            case 'Orçamentos':
              setBudgetId(numberOrUndefined)
              setStorageBudgetId(numberOrUndefined ? String(numberOrUndefined) : null)
              break
            case 'Tipo de orçamento':
              setType(stringOrUndefined)
              setStorageType(
                stringOrUndefined && stringOrUndefined !== 'false' ? String(stringOrUndefined) : null
              )
              break
            default:
              if (value !== undefined)
                console.warn('Received', column, 'with filter value', value, 'but it was not handled')
          }
        }}
        widthBeforeHorizontalScroll={1320}
        toggleSwitchButton={true}
        toggleSwitchText="Categorização automática"
        isToggleSwitchChecked={family?.autoCategorization}
        onToggleSwitch={(enabled) => {
          if (enabled && autoCategorizationNeverEnabled) setConfirmFirstCheck(true)
          else if (enabled && !autoCategorizationNeverEnabled)
            toggleFamilyAutoCategorization({ enabled, familyId })
          else if (!enabled) setConfirmDisable(true)
        }}
      />

      <ModalDeleteTransaction
        open={modalConfirmDelete}
        onSuccess={() => {
          unselectAll()
          refetchLancamentos()
          setModalConfirmDelete(false)
        }}
        onClose={() => setModalConfirmDelete(false)}
        data={selectedRows}
        // editing
      />
    </>
  )
}
