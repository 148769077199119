import styled from 'styled-components'

const WrapperForModal = styled.div<{ usePrimary?: boolean }>`
  .skylight-close-button {
    background: ${({ usePrimary, theme: { colors } }) =>
      usePrimary ? colors.primary : colors.secondary} !important;
  }
`

export default WrapperForModal
