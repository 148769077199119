import styled from 'styled-components'

export const TableEstimatedReal = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 1rem;
  table-layout: fixed;
  box-sizing: border-box;
  width: 100%;

  &#whitelabel-table {
    th {
      padding: 10px !important;
      font-weight: bold;
    }
    td {
      color: #7a97bf;
      font-weight: bold;
      padding: 5px 10px !important;
    }
  }
`

export const TableEstimatedRealThead = styled.thead`
  border-bottom: 1px solid rgb(221, 221, 221);
`

export const TableEstimatedRealTheadTitle = styled.h3<{ color: string }>`
  color: ${({ color }) => color} !important;
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0px;
  margin: 0;
`

export const TableEstimatedRealTd = styled.td`
  white-space: normal;
  padding-left: 1rem;
  font-size: 11px;
`

export const TableEstimatedRealTdColor = styled.td<{ color: string }>`
  color: ${({ color }) => color} !important;
  font-size: 12px;
`
