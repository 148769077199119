import React, { FunctionComponent, useCallback, useState } from 'react'
import Theme, { defaultTheme } from '../../domain/theme'
import { ThemeProvider } from 'styled-components'
import { getTheme } from '../../domain/theme/api/getTheme'
import { useObjectLocalStorage } from '../useLocalStorage'

interface ContextValue {
  theme: Theme
  changeTheme: (themeName: string) => Promise<void>
}

interface Props {
  children: React.ReactNode
}

const Context = React.createContext<ContextValue>({
  theme: defaultTheme,
  changeTheme: async () => {}
})

const isDefaultTheme = (themeName: string) =>
  !themeName || themeName === 'default' || themeName === 'undefined' || themeName === 'null'

const Provider: FunctionComponent<Props> = ({ children }) => {
  const [usingDefaultTheme, setIsUsingDefaultTheme] = useState(true)
  const { value: theme, setValue: setTheme } = useObjectLocalStorage('theme-content', defaultTheme)

  const changeTheme = useCallback(
    async (themeName: string) => {
      if (isDefaultTheme(themeName)) {
        if (!usingDefaultTheme) {
          setIsUsingDefaultTheme(true)
          setTheme(defaultTheme)
        } else {
          console.debug('Already using default theme, skipping theme change')
        }
        return
      }
      try {
        const newTheme = await getTheme(themeName)
        setIsUsingDefaultTheme(false)
        setTheme(newTheme)
      } catch (err) {
        console.error('Error setting new theme as', themeName, err)
      }
    },
    [usingDefaultTheme, setTheme]
  )

  console.log({ theme, usingDefaultTheme })
  const currentTheme = theme || defaultTheme

  return (
    <Context.Provider value={{ theme: currentTheme, changeTheme }}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </Context.Provider>
  )
}

export { Provider as ThemeProvider, Context as ThemeContext }
export type { Props as ThemeProviderProps, ContextValue as ThemeContextValue }
