import React, { useEffect } from 'react'
import Modal from '../Modal'
import { Button } from '../../Buttons'
import * as S from './styles'
import { ISaveButtonModal } from './types'

export default ({
  open,
  onClose,
  children,
  onSave,
  tooltip,
  disabledSaveButton,
  isLoading,
  loadingMessage,
  closeable
}: ISaveButtonModal) => {
  useEffect(() => {
    const keysListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose?.()
    }

    document.addEventListener('keydown', keysListener)

    return () => {
      document.removeEventListener('keydown', keysListener)
    }
  }, [onClose])

  return (
    <Modal
      closeable={closeable}
      open={open}
      onClose={onClose}
      hideBackground={true}
      isLoading={isLoading}
      loadingMessage={loadingMessage}
    >
      <S.Container>
        {children}
        <S.ButtonContainer>
          <Button text="Salvar" onClick={onSave} tooltip={tooltip} disabled={disabledSaveButton} />
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  )
}
