import styled from 'styled-components'

const WrapperForButtonBorder = styled.div`
  flex-grow: 1;
  .button-border,
  #aporteExtraMes > div {
    border: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
    color: ${({ theme: { colors } }) => colors.primary} !important;
    border-radius: 24px;
    margin-left: 8px;
  }
  .active {
    margin-left: 8px;
    border-radius: 24px;
    background: ${({ theme: { colors } }) => colors.secondary} !important;
    color: #fff !important;
  }
  #campoCalculado > div {
    border: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
    color: ${({ theme: { colors } }) => colors.primary} !important;
  }
  #campoCalculado > div > div > div {
    color: ${({ theme: { colors } }) => colors.primary} !important;
  }
  .button-border > div > div > div {
    color: ${({ theme: { colors } }) => colors.primary} !important;
  }
  .button-border span {
    color: ${({ theme: { colors } }) => colors.primary} !important;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: ${({ theme: { colors } }) => colors.secondary} !important;
    }
  }
  .active > .button-border span {
    width: 100%;
    color: #fff !important;
  }
  .width {
    width: 100% !important;
  }
  .widthBugFixSelect > div {
    width: 240px !important;
    padding: 0 !important;
  }
  &.bugFix {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
`

export default WrapperForButtonBorder
