// import { ajuda, ajudaPlanejador } from './constants'

export const getPublicUrl = () => {
  const publicUrl = process.env.PUBLIC_URL
  const pathParts = publicUrl.split('/')
  const contextPath = pathParts[pathParts.length - 1]
  return window.location.origin + '/' + contextPath
}

export const bodyScroll = (open) => {
  const body = document.getElementsByTagName('html')[0]
  const className = '--modal-open'
  const hasClassName = body.classList.contains(className)

  if (open && !hasClassName) {
    body.classList.add(className)
  }

  if (!open && hasClassName) {
    body.classList.remove(className)
  }
}

const baseApiUrl = process.env.REACT_APP_API_BASE_URL
export const server_api_new = baseApiUrl

export const termoId = '8a6b8fce-7340-4170-9f60-dd674fe6b4d4'
export const email = ''
export const senha = ''

export const idSonhoAposentadoria = 119

export const spinnerActive = true
export const spinnerMessage = 'Processando...'
export const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]
export const estadoCivil = ['Solteiro', 'Casado', 'Divorciado', 'Viúvo']
export const tipoEmpresa = ['PessoaFisica', 'PessoaJuridica']
export const tipoUsuario = ['Admin', 'Planejador', 'Pessoa']
//export const tipoRecorrencia = [ 'Mensal', 'Bimestral', 'Trimestral', 'Semestral', 'Anual' ];
export const tipoRecorrencia = ['Mensal', 'Avulso']
export const tipoOrcamento = ['Receita', 'Despesa', 'Investimento', 'Divida']
export const tipoMeioPagamento = [
  'Dinheiro',
  'Pré-pago / Vale / Ticket',
  'Cartão Crédito',
  'Instituição Financeira'
]
export const statusDivida = ['Aberto', 'Atrasado', 'Pago']
export const statusSonho = ['Não Iniciado', 'Em Progresso', 'Pausado', 'Concluído']
export const tipoPatrimonio = ['Imóvel', 'Veículo', 'Empresa', 'Investimento', 'Outros']
export const bandeira = ['Visa', 'Master', 'Elo', 'Amex', 'Diners', 'Hiper']
export const frequenciaCategoria = ['Mensal', 'Anual']
export const grupoDeCategoria = [
  'Alimentação',
  'Educação',
  'Lazer',
  'Moradia',
  'Transporte',
  'Receitas',
  'Investimento',
  'Dívida',
  'Saúde',
  'Outros',
  'Pessoal',
  'Viagens',
  'Bem Estar',
  'Trabalho',
  'Impostos',
  'Integração Bancária'
]
export const statusDaCategoria = ['Ativa', 'Historica']
export const indexador = ['TR', 'PRE', 'CDI', 'OUTRO']
export const amortizacao = ['SAC', 'PRICE', 'OUTRO']
export const statusFamilia = ['Aberto', 'Pago', 'Atrasado']

export const proRata7Dias = true

export const grupoCategoriaSonho = {
  '-1': 'Outros',
  1: 'Viagens',
  2: 'Aventuras',
  3: 'Pessoal',
  4: 'Esportes',
  5: 'Carreira / Educação',
  6: 'Família',
  7: 'Saúde / Bem Estar',
  8: 'Generosidade',
  9: 'Compras',
  10: 'Aposentadoria'
}

export const tipoInvestimento = {
  1: 'Fundos',
  2: 'Previdência',
  6: 'Renda Fixa Pré-fixado',
  3: 'Renda Fixa Pós-fixado',
  4: 'Tesouro Direto',
  5: 'Poupança',
  8: 'Ações, FII, ETFs',
  9: 'Debêntures',
  10: 'Moedas',
  99: 'Produto Customizado'
  //7: 'Bitcoin',
}

export const naturezaFundo = { 1: 'Fundo', 2: 'Previdência' }
export const metodologiaDePreco = { 1: 'Por Valor', 2: 'Por Variação' }
export const tipoRetirada = [
  { value: 1, label: 'Resgate' },
  { value: 3, label: 'Come Cotas' }
]
export const operacao = [
  { value: 0, label: 'Aplicação' },
  { value: 1, label: 'Resgate' },
  { value: 2, label: 'Saldo Inicial' }
]

export const operacao2 = ['Aplicação', 'Resgate', 'Saldo Inicial']

export const taxa = 0.4
export const inflacao = 4
export const planosCarteiras = [1, 2]
export const planosCarteiras2 = { 1: 'Plano Individual', 2: 'Plano Família' }
export const carenciaCobranca = 7

export const tipoConsolidacao = {
  1: 'Desde o Início',
  2: 'Ano Corrente',
  3: 'Últimos 12 Meses'
}
export const tipoGrafico = { 1: 'Geral', 2: 'Rentabilidade' }

export const spinnerShow = function ($) {
  const html = $('html') //IE7 won't have that
  html.css('overflow', 'hidden')
  // window.scrollTo(0, 0)
  $('._loading_overlay_wrapper').show()
}

export const spinnerHide = function ($, _scrollPosition) {
  // const currentScroll = [
  //   document.documentElement.scrollLeft || document.body.scrollLeft,
  //   document.documentElement.scrollTop || document.body.scrollTop
  // ]
  // const scroll = scrollPosition ?? currentScroll

  $('._loading_overlay_wrapper').hide()
  $('html').css('overflow', 'auto')
  // window.scrollTo(scroll[0], scroll[1])
}

export const showModal = (skyLightModal) => {
  // if (typeof window.scrollTo === 'function') {
  //   window.scrollTo(0, 0)
  // }
  skyLightModal.show()
}

export const logarErroDeRequisicao = function (error) {
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message)
  }

  console.log(error.config)
}

export const maskCurrency = function (e) {
  let value = e.target.value
  let formattedValue = value

  if (value !== '') {
    value = value.replace(/\D/g, '')
    value = Number(Number(value) / 100)

    formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
  }

  e.target.value = formattedValue
}

export const textLabels = {
  body: {
    noMatch: 'Nenhum registro encontrado',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
  },
  pagination: {
    next: 'Próxima',
    previous: 'Anterior',
    rowsPerPage: 'Registros por página:',
    displayRows: 'de'
  },
  toolbar: {
    search: 'Procurar',
    downloadCsv: 'Baixar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Colunas',
    filterTable: 'Filtrar Tabela'
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR'
  },
  viewColumns: {
    title: 'Mostrar Colunas',
    titleAria: 'Mostrar/Esconder colunas'
  },
  selectedRows: {
    text: 'registro(s) selecionado(s)',
    delete: 'Remover',
    deleteAria: 'Remover registros selecionados'
  }
}

export const diferencaEntreDatasEmDias = function (dataInicio, dataFim) {
  const diffMilissegundos = dataFim - dataInicio
  const diffSegundos = diffMilissegundos / 1000
  const diffMinutos = diffSegundos / 60
  const diffHoras = diffMinutos / 60
  const diffDias = diffHoras / 24
  return diffDias
}

export const retornoMes = (mesExt) => {
  if (mesExt === 'Jan') {
    return '01'
  } else if (mesExt === 'Fev') {
    return '02'
  } else if (mesExt === 'Mar') {
    return '03'
  } else if (mesExt === 'Abr') {
    return '04'
  } else if (mesExt === 'Mai') {
    return '05'
  } else if (mesExt === 'Jun') {
    return '06'
  } else if (mesExt === 'Jul') {
    return '07'
  } else if (mesExt === 'Ago') {
    return '08'
  } else if (mesExt === 'Set') {
    return '09'
  } else if (mesExt === 'Out') {
    return '10'
  } else if (mesExt === 'Nov') {
    return '11'
  } else if (mesExt === 'Dez') {
    return '12'
  }
}

export const convertToFloat = (dado) => {
  const temp = dado + ''
  if (temp.includes(',')) {
    return parseFloat(temp.trim().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.'))
  } else {
    return parseFloat(temp.trim().replaceAll('R$', ''))
  }
}
