import React, { useState } from 'react'
import { SimplifiedOrcamentoWithCategory, TipoOrcamento } from '../../../domain/orcamento/Orcamento'
import { useCurrentFamilyId } from '../../../hooks'
import { useFamilyOrcamentosForTable } from '../../../queries/orcamento/queries'
import Select from '../Select'
import { Selectable } from '../Select/Options'

interface Props {
  onSelect: (newOrcamento: SimplifiedOrcamentoWithCategory) => void
  selectedId?: number
  defaultPlaceholder?: string
  type?: TipoOrcamento
}

const OrcamentoSelect = ({ selectedId, type, defaultPlaceholder, onSelect }: Props) => {
  const [filter, setFilter] = useState('')
  const { familyId } = useCurrentFamilyId()
  const { orcamentos, isLoadingOrcamentos } = useFamilyOrcamentosForTable(familyId)

  const options: Selectable<SimplifiedOrcamentoWithCategory>[] =
    orcamentos
      ?.map((o) => ({
        value: o,
        text: o.nome
      }))
      .filter(({ text }) => text?.toLowerCase().includes(filter?.toLowerCase()))
      .filter(({ value: { tipo } }) => type === undefined || tipo === type) ?? []
  const placeholder =
    orcamentos?.find(({ id }) => id === selectedId)?.nome || defaultPlaceholder || 'Selecione...'

  return (
    <Select
      filter={filter}
      onFilter={setFilter}
      options={options}
      loading={isLoadingOrcamentos}
      placeholder={placeholder}
      onSelection={(novoOrcamento) => {
        setFilter('')
        onSelect(novoOrcamento)
      }}
    />
  )
}

export default OrcamentoSelect
