import { useFamilyStore } from '../../../../store/family/useFamilyStore'
import * as S from './styles'

export const ClientSelector = ({
  colors,
  role
}: {
  colors: {
    prmary?: string
    primaryDarken?: string
    secondary?: string
    secondaryDarken?: string
  }
  role: 0 | 1 | 2
}) => {
  const { family, defaultImage } = useFamilyStore()
  const colorScheme = {
    containerBg: role === 2 ? colors.primaryDarken : colors?.secondaryDarken
  }

  console.log({ family, defaultImage })

  return (
    <S.FamilyContainer color={colorScheme.containerBg}>
      <S.FamilyFigure>
        <img src={family?.image || defaultImage} alt={family?.nome || ''} />
      </S.FamilyFigure>

      <div>
        <S.FamilyName>{family?.nome || 'Selecione um cliente'}</S.FamilyName>
      </div>

      <S.FamilyIconContainer>
        <S.FamilyIcon className="fas fa-chevron-right" />
      </S.FamilyIconContainer>
    </S.FamilyContainer>
  )
}
