import React from 'react'
import { Button } from '..'
import { getRoute, getRoutePath } from '../../../routes'

export default () => {
  const goBack = () => {
    if (window.history.length <= 1) window.open(getRoutePath(getRoute('Home')), '_self')
    else window.history.back()
  }
  return <Button text="Voltar" onClick={goBack} />
}
