import React from 'react'
import Logo from '../../../../theme/logo'
import { ClientSelector } from '../../../../components/Sidebar/components/ClienteSelector'
import * as S from './styles'
import browserBar from '../../../../assets/img/whitelabel/browser-bar.png'
import { IPreviewProps } from './types'
import { TableEstimatedReal } from './TableEstimatedReal'

const roles = {
  0: 'Administrador',
  1: 'Gestor/Planejador',
  2: 'Familia'
}

export const SidebarPreview = ({ logo, role, colors }: IPreviewProps) => {
  const colorMap = {
    bgColor: role === 2 ? colors.primary : colors.secondary,
    bannerBgColor: role === 2 ? colors.primaryDarken : colors.secondaryDarken
  }

  return (
    <S.PreviewContainer
      style={{
        backgroundColor: colorMap.bgColor
      }}
    >
      <div>
        <S.LogoContainer>
          <Logo logo={logo} />
        </S.LogoContainer>

        {role <= 1 && (
          <S.Banner className="banner" bgColor={colorMap.bannerBgColor}>
            {roles[role]}
          </S.Banner>
        )}
      </div>

      <ClientSelector role={role} colors={colors} />

      <hr />

      <nav></nav>
    </S.PreviewContainer>
  )
}

export const PagePreview = ({ logo, role, colors }: IPreviewProps) => {
  return (
    <div>
      <h4>
        {role === 2 && (
          <>
            Visão da <strong>{roles[role]}</strong>
          </>
        )}
        {role <= 1 && (
          <>
            Visão do <strong>{roles[role]}</strong>
          </>
        )}
      </h4>

      <S.BrowserContainer>
        <S.BrowserBarImage src={browserBar} alt="" />

        <S.BrowserGroupContainer>
          <SidebarPreview logo={logo} role={role} colors={colors} />
          <S.BrowserContent>
            <p>
              <S.ButtonNewTransaction colors={colors}>
                <S.IconStar className="fas fa-star" />
                Novo lançamento
              </S.ButtonNewTransaction>
            </p>

            <div>
              <S.TableContainer>
                <TableEstimatedReal colors={colors} />
              </S.TableContainer>
            </div>
          </S.BrowserContent>
        </S.BrowserGroupContainer>
      </S.BrowserContainer>
    </div>
  )
}
