export const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/webp']
type TAllowedTypes = (typeof allowedTypes)[number]

export interface IFile {
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: TAllowedTypes
  webkitRelativePath: string
}

export interface ISideBarEditor {
  className?: string
  onLogoChange: ({ logo }: { logo: string }) => void
  onPrimaryColorChange: (color: string) => void
  onPrimaryDarkenColorChange: (color: string) => void
  onSecondaryColorChange: (color: string) => void
  onSecondaryDarkenColorChange: (color: string) => void
}
