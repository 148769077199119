import styled from 'styled-components'

export const FamilyContainer = styled.button<{ color?: string }>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin: 0 auto;
  min-height: 60px;
  padding: 0;
  position: relative;
  width: 95%;

  &:after {
    content: '';
    background-color: ${({ color }) => color && color};
    border-radius: 1.5rem;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

export const FamilyFigure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  height: 60px;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 60px;
  z-index: 2;

  img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
`

export const FamilyName = styled.h4`
  color: #fff;
  margin: 0;
  padding: 0;
  max-width: 140px;
  text-align: left;
  position: relative;
  z-index: 2;
`

export const FamilyIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  z-index: 2;
`

export const FamilyIcon = styled.i`
  color: #fff;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
`
