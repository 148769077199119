import React, { useContext, useEffect } from 'react'
import { ThemeContext, AuthContext, useBooleanLocalStorage } from '../../hooks'
import { ThemeContextValue } from '../../hooks/contexts/themeContext'
import { AuthContextValue } from '../../hooks/contexts/authContext'
import { useCoreContext } from '../../hooks/contexts/coreProvider'
import { useFamilyQuery, useGetImageBYFamilyId } from '../../queries/family/queries'
import { Family } from '../../domain/family/Family'
import { IDiagnosticStore, useDiagnosticStore } from '../../store/Diagnostic'
import { useDateLocalStorage, useLocalStorage } from '../../hooks/useLocalStorage'
// import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import { endOfDay, endOfMonth, formatISO, startOfDay, startOfMonth } from 'date-fns'
import moment from 'moment'

type H = ReturnType<typeof useHistory>
interface ComponentProps {
  authContext?: AuthContextValue
  authenticated?: boolean
  empresaId?: number | null
  familiaId?: number | null
  family?: Family
  familyImage?: string
  setEmpresaId: (id: string | null) => void
  setFamiliaId: (id: string | null) => void
  tipoUsuario?: number | null
  themeContext?: ThemeContextValue
  planejadorId?: number | null
  diagnostic?: IDiagnosticStore
  mostrarModalAposentadoria: boolean
  setMostrarModalAposentadoria: (value: boolean) => void
  dataInicio: Date
  setDataInicio: (value: Date) => void
  dataFim: Date
  setDataFim: (value: Date) => void
  setUsuarioEmail: (id: string | null) => void
  usuarioEmailSetado: string | null
  setTipoUsuario: (id: string | null) => void
  tipoUsuarioSetado: 0 | 1 | 2 | string | null
  userId: string | null
  setUsuarioId: (id: string | null) => void
  pessoaId: string | null
  history: H
  recaptcha: {
    handleReCaptchaVerify: () => Promise<void>
    token: string | null
  }
  removeFamiliaId: () => void
}

export const withContexts =
  (Component: (props: ComponentProps) => JSX.Element) => (props: ComponentProps) => {
    const themeContext = useContext(ThemeContext)
    const authContext = useContext(AuthContext)
    const { token } = authContext || {}
    const {
      authenticated,
      setUsuarioEmail,
      usuarioEmailSetado,
      setTipoUsuario,
      tipoUsuarioSetado,
      userId,
      setUsuarioId,
      pessoaId,
      empresaId,
      setEmpresaId,
      familiaId: familyId,
      planejadorId
    } = useCoreContext()
    const { setValue: setFamiliaId } = useLocalStorage('familia-id')
    const history = useHistory()
    const recaptcha = useRecaptcha()
    const { family } = useFamilyQuery(familyId ? parseInt(familyId as unknown as string) : undefined)
    const { familyImage } = useGetImageBYFamilyId({ familyId: familyId })
    const diagnostic = useDiagnosticStore()
    const tipoUsuario = tipoUsuarioSetado ? parseInt(tipoUsuarioSetado as string) : token?.type
    const dateStart = new Date(formatISO(startOfDay(startOfMonth(new Date()))))
    const dateEnd = new Date(formatISO(endOfDay(endOfMonth(new Date()))))

    const { value: mostrarModalAposentadoria, setValue: setMostrarModalAposentadoria } =
      useBooleanLocalStorage('mostrarModalAposentadoria', true)
    const { value: dataInicio, setValue: setDataInicio } = useDateLocalStorage('dataInicio', dateStart)
    const { value: dataFim, setValue: setDataFim } = useDateLocalStorage('dataFim', dateEnd)

    console.log({
      dateStart,
      dateEnd
    })

    useEffect(() => {
      if (!dataInicio) {
        setDataInicio(moment().startOf('month').toDate())
      }

      if (!dataFim) {
        setDataFim(moment().endOf('month').toDate())
      }
    }, [dataInicio, dataFim, setDataFim, setDataInicio])

    return (
      <Component
        {...props}
        authContext={authContext}
        authenticated={authenticated}
        dataFim={dataFim}
        dataInicio={dataInicio}
        empresaId={empresaId ? Number(empresaId) : null}
        familiaId={familyId}
        family={family || ({} as Family)}
        familyImage={familyImage}
        history={history}
        mostrarModalAposentadoria={mostrarModalAposentadoria}
        pessoaId={pessoaId}
        planejadorId={planejadorId ? Number(planejadorId) : null}
        recaptcha={recaptcha}
        setDataFim={setDataFim}
        setDataInicio={setDataInicio}
        setEmpresaId={setEmpresaId}
        setFamiliaId={(familyId) => setFamiliaId(familyId || null)}
        setMostrarModalAposentadoria={setMostrarModalAposentadoria}
        setTipoUsuario={setTipoUsuario}
        setUsuarioEmail={setUsuarioEmail}
        setUsuarioId={setUsuarioId}
        themeContext={themeContext}
        diagnostic={diagnostic}
        tipoUsuario={typeof tipoUsuario === 'number' ? tipoUsuario : null}
        tipoUsuarioSetado={tipoUsuarioSetado}
        userId={userId}
        usuarioEmailSetado={usuarioEmailSetado}
      />
    )
  }
