import { noticeError } from '../../../services/Monitoring'
import { postWithToken } from '../../../webClient'
import { Results } from '../../Results'
import { postWithTokenApiV2 } from '../../../webClient'
import { ManyResults } from '../../Results'

export interface IOrcamentoPrevistoRealizado {
  classePaiId: number
  id: number
  nome: string
  orcamentoNome: string
  ano: number
  mes: number
  estimado: number
  total: number
  totalMedio: number
  media: number
  minimo: number
  maximo: number
  quantidade: number
  tipoOrcamento: string
}

export const postTipoOrcamentoPrevistoRealizadoApi = async ({
  familyId,
  filter
}: {
  familyId?: number
  filter: {
    DataInicial?: string
    DataFinal?: string
  }
}) => {
  if (!familyId) {
    console.log('fetchByFamily: familyId is required')
    return
  }
  const url = `/dashboard/tipo-orcamento-previsto-realizado/${familyId}`
  const { data, status } = await postWithToken<Results<IOrcamentoPrevistoRealizado[]>, unknown>(url, filter)

  if (status !== 200) {
    const msg = 'Error getting tipo orcamento previsto realizado'
    console.error(msg)
    throw new Error(msg)
  }

  return data
}

interface IOrcamento {
  categoriaId: number
  categoriaSuspenso: number
  estimado: number
  frequencia: number
  grupo: number
  maximo: number
  media: number
  meioPagamentoPadraoId: number
  minimo: number
  nomeCategoria: string
  nomeMeioPagamento: string
  nomeOrcamento: string
  orcamentoId: number
  orcamentoSuspenso: number
  quantidade: number
  tipoOrcamento: number
  total: number
  totalMedio: number
}

export const postDashboardOrcamentoPeriodoByFalimyIdApi = async ({ familyId, filter }) => {
  const url = `/api/dashboard/orcamento-no-periodo/${familyId}`

  const {
    data: { results: data },
    status
  } = await postWithTokenApiV2<ManyResults<IOrcamento>, object>(url, filter)

  if (status !== 200) {
    const msg = `Error when getting dashboard by family ID ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const postDashboardCategoriaPrevistoRealizadoMesApi = async ({ familyId, categoryId, filter }) => {
  if (!familyId || !categoryId) {
    throw new Error('Missing familyId or categoryId')
  }

  const url = `/api/dashboard/categoria-previsto-realizado-mes-a-mes/${familyId}/${categoryId}`

  const {
    data: { results: data },
    status
    // TODO: Change response TYPE
  } = await postWithTokenApiV2<ManyResults<IOrcamento[]>, object>(url, filter)

  if (status !== 200) {
    const msg = `Error when getting dashboard by family ID ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
