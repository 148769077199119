import React, { useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import ColorPicker from 'react-pick-color'

export const SelectColorSeletor = ({
  id,
  color,
  title,
  setColor,
  onColorChange,
  onOpen,
  current
}: {
  current?: string
  id: string
  color: string
  title: string
  onOpen?: (id: string) => void
  setColor: (color: string) => void
  onColorChange: (color: string) => void
}) => {
  const [openPicker, setOpenPicker] = useState(false)
  let keydownListener: ((event?: KeyboardEvent) => void) | null

  keydownListener = (event) => {
    if (event && ['Enter', 'Escape'].includes(event.key)) {
      setOpenPicker(false)
    }
  }

  const handleClosePicker = useCallback(() => {
    document.removeEventListener('keydown', keydownListener as (event?: KeyboardEvent) => void)
    keydownListener = null
    setOpenPicker(false)
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (!openPicker) {
      document.addEventListener('keydown', (event) => keydownListener?.(event))
      onOpen?.(id)
      setOpenPicker(true)
    } else {
      handleClosePicker()
    }
  }

  useEffect(() => {
    if (current !== id) {
      handleClosePicker()
    }
  }, [current, handleClosePicker, id])

  return (
    <S.Field id={id}>
      <label className="bold">{title}</label>
      <S.ColorButton color={color} onClick={handleClick}>
        <span>Selecione</span>
      </S.ColorButton>

      <S.ColorPicker visible={openPicker}>
        <ColorPicker
          color={color}
          onChange={(color) => {
            setColor(color.hex)
            onColorChange(color.hex)
          }}
          hideAlpha
        />
      </S.ColorPicker>
    </S.Field>
  )
}
