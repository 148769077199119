import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  gap: 0.8rem;
  width: 220px;
`

export const Field = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  cursor: pointer;

  label.bold {
    font-size: 12px;
    font-weight: bold;
  }
`

export const ColorButton = styled.button<{ color?: string }>`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  transition: background 200ms ease-in-out;

  &:after {
    background-color: ${({ color }) => color && color};
    border-radius: 50%;
    content: '';
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: #f8f6f6;
  }

  span {
    font-size: 14px;
  }
`

export const ColorPicker = styled.div<{ visible: boolean }>`
  position: relative;
  > div {
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
  }
`

export const SelectImage = styled.label`
  cursor: pointer;
  border: 1px solid #ececec;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1rem;
  transition: border ease-in-out 200ms;
  width: 100%;

  span {
    font-size: 12px;
  }

  &:hover {
    border-color: #ccc;
  }

  input[type='file'] {
    visibility: hidden;
    position: absolute;
  }
`

export const SelectImageIcon = styled.i`
  &:before {
    font-size: 20px;
  }
`

export const LogoImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`
