import styled, { css } from 'styled-components'
import { IPreviewColor } from './types'

export const PreviewContainer = styled.nav`
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;

  img {
    margin: 0;
    max-width: 90%;
    max-height: 120px;
  }
`

export const Banner = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor && bgColor};
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  height: 30px;
  line-height: 28px;
  margin: 2px auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 90%;
`

export const BrowserContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 1031px;
`

export const BrowserBarImage = styled.img`
  max-width: 1031px;
  max-height: 65px;
  width: 100%;
`

export const BrowserGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  width: 100%;
`

export const ButtonNewTransaction = styled.button<{ colors: IPreviewColor }>`
  align-items: center;
  background: ${({ colors }) =>
    colors
      ? css`
    linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%);
  `
      : 'linear-gradient(45deg, rgb(16, 164, 195) 0%, rgb(148, 61, 255) 100%)'};
  border-radius: 25px;
  border: none;
  color: rgb(255, 255, 255);
  display: flex;
  flex-flow: row;
  font-size: 0.88rem;
  gap: 5px;
  justify-content: center;
  padding: 4px 10px;
  pointer-events: none;
`

export const IconStar = styled.i`
  color: #fdd916;
  font-size: 0.8rem;
`

export const TableContainer = styled.div`
  background-color: #e4eaf4;
  overflow: hidden;
  border-radius: 15px;
  width: 325px;
`
