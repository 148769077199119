import React, { useCallback, useState } from 'react'
import { useNumericLocalStorage } from '../../../hooks'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { usePlannerList } from '../../../queries/planner/queries'
import { noticeError } from '../../../services/Monitoring'
import { Button } from '../../Buttons'
import styled from 'styled-components'
import Table from '../Table'
import { useColumns } from './columns'
import { getLoadingPlanner } from '../../../domain/planner'
import Footer from './Footer'
import PlannerBillingTable from '../BillingsByPlannerIdTable'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  width: fit-content;
`

interface Sort {
  prop: string
  desc: boolean
}

const defaultSort: Sort = { desc: true, prop: 'creationDate' }
const loadingData = getLoadingPlanner()

export default ({ filterDate }) => {
  const token = useRequiredAuth()
  const [search, setSearch] = useState<string>()
  const [page, setPage] = useState(0)
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage('planners-page-size', 10)
  const [sort, setSort] = useState<Sort>(defaultSort)
  const onSort = useCallback((columnId: string, order: 'desc' | 'asc' | 'none') => {
    if (order === 'none') setSort(defaultSort)
    else setSort({ desc: order === 'desc', prop: columnId })
  }, [])
  const [includeDeleted, setIncludeDeleted] = useState<boolean | undefined>(false)

  const { plannersList, isPlannersListError, refetchPlannersList, isLoadingPlannersList, plannersListError } =
    usePlannerList(search, includeDeleted, sort?.prop, sort?.desc, page, pageSize)
  const totalPages = plannersList ? Math.ceil(plannersList.totalResults / pageSize) : 1

  const columns = useColumns()

  if (isPlannersListError) {
    noticeError(plannersListError, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchPlannersList()} />
      </ErrorContainer>
    )
  }

  return (
    <Table
      title="Planejadores"
      data={plannersList?.results || []}
      columns={columns}
      isLoading={isLoadingPlannersList}
      loadingData={loadingData}
      filterEnabled={true}
      onFilterChange={setSearch}
      pageable={true}
      pageIndex={page}
      pageSize={pageSize}
      totalPages={totalPages}
      onNextPage={() => setPage(page + 1)}
      onPreviousPage={() => setPage(page - 1)}
      onPageSizeChange={setPageSize}
      sortable={true}
      onSort={onSort}
      downloadFileName="Fatura"
      filterableColumnNames={['Incluir inativos']}
      onColumnFilterChange={(column, value) => {
        if (column === 'Incluir inativos' && (value === undefined || typeof value === 'boolean'))
          setIncludeDeleted(value)
        else console.warn('Received unknown filter: ', column, value)
      }}
      renderFooter={() => <Footer planners={plannersList} />}
      renderExpanded={({ id }) => (
        <PlannerBillingTable plannerId={id} widthBeforeHorizontalScroll={499} filterDate={filterDate} />
      )}
      widthBeforeHorizontalScroll={1382}
    />
  )
}
