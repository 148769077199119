import { useMutation } from 'react-query'
import {
  postDashboardCategoriaPrevistoRealizadoMesApi,
  postDashboardOrcamentoPeriodoByFalimyIdApi
} from '../../domain/dashboard/api'

export const cacheBaseKey = 'dashboard'

export const usePostDashboardOrcamentoPeriodoByFalimyIdMutation = () => {
  const {
    mutateAsync: postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoading: isLoadingPostDashboardOrcamentoPeriodoByFalimyId,
    isError: isErrorPostDashboardOrcamentoPeriodoByFalimyId,
    error: postDashboardOrcamentoPeriodoByFalimyIdError,
    isSuccess: postDashboardOrcamentoPeriodoByFalimyIdSuccess,
    reset: resetPostDashboardOrcamentoPeriodoByFalimyId,
    data
  } = useMutation(postDashboardOrcamentoPeriodoByFalimyIdApi, {
    retry: false
  })

  return {
    postDashboardOrcamentoPeriodoByFamilyIdAsync,
    isLoadingPostDashboardOrcamentoPeriodoByFalimyId,
    isErrorPostDashboardOrcamentoPeriodoByFalimyId,
    postDashboardOrcamentoPeriodoByFalimyIdError,
    postDashboardOrcamentoPeriodoByFalimyIdSuccess,
    resetPostDashboardOrcamentoPeriodoByFalimyId,
    data
  }
}

export const usePostDashboardCategoriaPrevistoRealizadoMes = () => {
  const {
    mutateAsync: postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoading: isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isError: isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    error: postDashboardCategoriaPrevistoRealizadoMesError,
    isSuccess: postDashboardCategoriaPrevistoRealizadoMesSuccess,
    reset: resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  } = useMutation(postDashboardCategoriaPrevistoRealizadoMesApi, {
    retry: false
  })

  return {
    postDashboardCategoriaPrevistoRealizadoMesAsync,
    isLoadingPostDashboardCategoriaPrevistoRealizadoMes,
    isErrorPostDashboardCategoriaPrevistoRealizadoMes,
    postDashboardCategoriaPrevistoRealizadoMesError,
    postDashboardCategoriaPrevistoRealizadoMesSuccess,
    resetPostDashboardCategoriaPrevistoRealizadoMes,
    data
  }
}
