import React from 'react'
import { useTransactionContext } from '../../hooks/contexts/transactionContext'
import { Transaction } from '../Navbars/AdminNavbar/components/SaveDataTransaction/types'

interface ComponentProps {
  setEditingEntry: (value: Transaction) => void
}

export const withTransationContexts =
  (Component: (props: ComponentProps) => JSX.Element) => (props: ComponentProps) => {
    const { setEditingEntry } = useTransactionContext()

    return <Component {...props} setEditingEntry={setEditingEntry} />
  }
