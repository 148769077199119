import * as React from 'react'
import * as S from './styles'

export const TableEstimatedReal = ({ colors }) => {
  return (
    <S.TableEstimatedReal id="whitelabel-table">
      <S.TableEstimatedRealThead>
        <tr>
          <th></th>
          <th>
            <S.TableEstimatedRealTheadTitle color={colors.secondary}>
              Estimativa
            </S.TableEstimatedRealTheadTitle>
          </th>
          <th>
            <S.TableEstimatedRealTheadTitle color={colors.primary}>Realizado</S.TableEstimatedRealTheadTitle>
          </th>
        </tr>
      </S.TableEstimatedRealThead>

      <tbody>
        <tr>
          <S.TableEstimatedRealTd>
            <div>
              <b>Receitas</b>
            </div>
          </S.TableEstimatedRealTd>
          <S.TableEstimatedRealTdColor color={colors.secondary}>&nbsp;25.416,67</S.TableEstimatedRealTdColor>
          <S.TableEstimatedRealTdColor color={colors.primary}>&nbsp;0,00</S.TableEstimatedRealTdColor>
        </tr>
        <tr>
          <S.TableEstimatedRealTd>
            <div>
              <b>Despesas</b>
            </div>
          </S.TableEstimatedRealTd>
          <S.TableEstimatedRealTdColor color={colors.secondary}>&nbsp;23.318,89</S.TableEstimatedRealTdColor>
          <S.TableEstimatedRealTdColor color={colors.primary}>&nbsp;560,00</S.TableEstimatedRealTdColor>
        </tr>
        <tr>
          <S.TableEstimatedRealTd>
            <div>
              <b>Investimentos</b>
            </div>
          </S.TableEstimatedRealTd>
          <S.TableEstimatedRealTdColor color={colors.secondary}>&nbsp;4.248,24</S.TableEstimatedRealTdColor>
          <S.TableEstimatedRealTdColor color={colors.primary}>&nbsp;0,00</S.TableEstimatedRealTdColor>
        </tr>
        <tr>
          <S.TableEstimatedRealTd>
            <div>
              <b>Dívidas</b>
            </div>
          </S.TableEstimatedRealTd>
          <S.TableEstimatedRealTdColor color={colors.secondary}>&nbsp;2.336,50</S.TableEstimatedRealTdColor>
          <S.TableEstimatedRealTdColor color={colors.primary}>&nbsp;0,00</S.TableEstimatedRealTdColor>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <S.TableEstimatedRealTd>
            <strong>Saldo</strong>
          </S.TableEstimatedRealTd>
          <S.TableEstimatedRealTdColor color={colors.secondary}>&nbsp;4.486,96</S.TableEstimatedRealTdColor>
          <S.TableEstimatedRealTdColor color={colors.primary}>&nbsp;560,00</S.TableEstimatedRealTdColor>
        </tr>
      </tfoot>
    </S.TableEstimatedReal>
  )
}
