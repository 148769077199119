import { useQuery } from 'react-query'
import { fetchByFamily } from '../../domain/categoria/api/fetchByFamily'
import { SimplifiedCategoria } from '../../domain/categoria/Categoria'
import { QueryOptions } from '../queryOptions'
import { fetchCategoryByFamilyIdFiltroApi } from '../../domain/categoriaOrcamento/api/fetchByFamily'
import { useCoreContext } from '../../hooks/contexts/coreProvider'
// import { useLocalStorage } from '../../hooks'
// import { useCallback, useEffect } from 'react'

const cacheKey = 'categoria'

export const useFamilyCategorias = (
  familyId: number,
  options?: QueryOptions<{ categorias: SimplifiedCategoria[] }>
) => {
  const {
    data,
    isError: isCategoriasError,
    isLoading: isLoadingCategorias,
    error: categoriasError
  } = useQuery<unknown, Error, { categorias: SimplifiedCategoria[] }>(
    [cacheKey, familyId],
    () => fetchByFamily(familyId),
    options
  )

  const { categorias } = data || {}

  return {
    categorias,
    isCategoriasError,
    isLoadingCategorias,
    categoriasError
  }
}

export const useGetFilteredCategories = ({ categoriaId }) => {
  const { familiaId: familyId } = useCoreContext()
  const { categorias, isLoadingCategorias } = useFamilyCategorias(familyId as number)

  let category: SimplifiedCategoria | undefined

  if (categorias?.length && categoriaId) {
    category = categorias?.find(({ id }) => id === categoriaId)
  }

  return { category, isLoadingCategorias }
}

export const useFectchCategoryByFamilyIdFiltro = ({
  familyId,
  filter
}: {
  familyId: number
  filter: { IncluirOrcamentos?: boolean }
}) => {
  const { authenticated } = useCoreContext()

  const {
    data,
    isError: isCategoriasFiltroError,
    isLoading: isLoadingCategoriasFiltro,
    error: categoriasFiltroError,
    refetch: refetchCategoriasFiltro
  } = useQuery([cacheKey, familyId], () => fetchCategoryByFamilyIdFiltroApi(familyId, filter), {
    enabled: !!familyId && authenticated
  })

  const { categorias: categoriasFiltro, totalResults } = data || {}

  return {
    categoriasFiltro,
    isCategoriasFiltroError,
    isLoadingCategoriasFiltro,
    categoriasFiltroError,
    totalResults,
    refetchCategoriasFiltro
  }
}
