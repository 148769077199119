import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay-ts'
import routes from '../routes.tsx'
import rightBarTopRoutes from '../rightBarTopRoutes'
import { withBetaUserEasterEgg } from '../components/HOC/withBetaUserEasterEgg'
import AdminNavbar from '../components/Navbars/AdminNavbar'
import { AuthContext } from '../hooks'
import { SessionTimeout } from '../components/SessionTimeout'
import { LoadingBackground } from '../components/Loading'
import { Oval } from 'react-loader-spinner'
import { Diagnostic } from '../components/Modal/Diagnostic'
import { SidebarV2 } from '../components/Sidebar'
import '../assets/css/spinner-aux.css'

class Admin extends React.Component {
  static contextType = AuthContext

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    if (this.refs?.mainContent) this.refs.mainContent.scrollTop = 0
  }

  filterIsAdminLayout = (route) => route.layout === '/admin'

  filterBetaRoutes = (route) => (this.props.betaUser ? true : !route.betaUserOnly)

  getSwitchRoutes = (routes) =>
    routes
      .filter(this.filterBetaRoutes)
      .filter(this.filterIsAdminLayout)
      .map(({ layout, path, component }, key) => {
        if (path && component) {
          return <Route path={layout + path} component={component} key={key} />
        }
      })

  getSidebarRoutes = (routes) => routes.filter(this.filterBetaRoutes)

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  render() {
    console.log({ routes: this.getSidebarRoutes(routes) })
    return (
      <>
        <SidebarV2 routes={this.getSidebarRoutes(routes)} id="sidenav-main" position="fixed-left" />

        <main className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            rightBarTopRoutes={rightBarTopRoutes}
          />
          <Suspense
            fallback={
              <LoadingBackground lowIndex>
                <Oval />
              </LoadingBackground>
            }
          >
            <Switch>{this.getSwitchRoutes(routes)}</Switch>
          </Suspense>
        </main>

        <SessionTimeout cacheTime={360} />
        <LoadingOverlay active={global.spinnerActive} spinner text={global.spinnerMessage} />
        <Diagnostic />
      </>
    )
  }
}

export default withBetaUserEasterEgg(Admin, [
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  ' ',
  'ArrowUp'
])
