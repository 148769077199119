import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { createContext, FC } from 'react'
import { ThemeProvider } from './themeContext'
import { TransactionContextProvider } from './transactionContext'
import { useLocalStorage } from '../useLocalStorage'
import { InitialDataContextProvider } from './initialDataContext'
import { ThemeModal } from '../../components/Modal'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useClearCacheEasterEgg } from '../useClearCacheEasterEgg'
import { useAuth } from './authContext'
import { SelectComboContextProvider } from './selectComboContext'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useFamilyQuery } from '../../queries/family/queries'
import { LoadingScreenProvider } from './loadingScreenContext'
import { LoadingScreen } from '../../components/Loading'
import { TutorialsProvider } from './tutorialsContext'
import { DashboardProvider } from './dashboardContext'

const muiTheme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        }
      }
    },
    // @ts-expect-error expected-error
    MUIDataTableSelectCell: {
      fixedHeaderCommon: {
        backgroundColor: 'unset'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      },
      footer: {
        color: '#FFF',
        fontWeight: 700
      }
    },
    MUIDataTableHeadCell: {
      fixedHeaderYAxis: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden'
      }
    },
    MuiToolbar: {
      regular: {
        backgroundColor: '#e4eaf4'
      }
    },
    MUIDataTablePagination: {
      root: {
        '&:last-child': {
          margin: '0px 24px 0px 24px',
          padding: 0
        }
      }
    }
  }
})

export interface ICoreContext {
  empresaId?: number | null
  familiaId?: number | null
  planejadorId?: number | null
  tipoUsuario?: 0 | 1 | 2 | string | null
  usuarioId?: number | null
  authenticated?: boolean
  userType?: {
    isPlanned?: boolean
    isPlanner?: boolean
    isManager?: boolean
    isSupport?: boolean
  }
  setFamiliaId: Dispatch<SetStateAction<string | null>>
  setTipoUsuario: Dispatch<SetStateAction<string | null>>
  setUsuarioEmail: Dispatch<SetStateAction<string | null>>
  setUsuarioId: Dispatch<SetStateAction<string | null>>
  setEmpresaId: Dispatch<SetStateAction<string | null>>
  usuarioEmailSetado: string | null
  tipoUsuarioSetado: 0 | 1 | 2 | string | null
  userId: string | null
  pessoaId: string | null
  loginRedirect: boolean
}

const CoreContext = createContext({
  authenticated: false,
  setFamiliaId: () => {},
  setEmpresaId: () => {},
  setTipoUsuario: () => {},
  setUsuarioEmail: () => {},
  setUsuarioId: () => {},
  usuarioEmailSetado: null,
  tipoUsuarioSetado: null,
  userId: null,
  pessoaId: null,
  loginRedirect: false
} as ICoreContext)

export const CoreContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { value: valueJwt } = useLocalStorage('jwt')
  const auth = useAuth()
  const { authenticated, token, isPlanner, isPlanned, isManager, isSupport } = auth || {}
  const {
    email: usuarioEmail,
    empresaId,
    familiaId,
    id: usuarioId,
    pessoaId,
    planejadorId,
    theme,
    type: tipoUsuario
  } = token || {}

  const { setValue: setEmpresaId } = useLocalStorage('empresa-id')
  const { setValue: setFamiliaId, value: storeFamiliaId } = useLocalStorage('familia-id')
  const { setValue: setTipoUsuario, value: tipoUsuarioSetado } = useLocalStorage('tipo-usuario')
  const { setValue: setUsuarioEmail, value: usuarioEmailSetado } = useLocalStorage('usuario-email')
  const { setValue: setUsuarioId, value: userId } = useLocalStorage('usuario-id')
  const finalFamiliaId = storeFamiliaId ? Number(storeFamiliaId) : familiaId ?? null
  const { family } = useFamilyQuery(finalFamiliaId ? finalFamiliaId : undefined)
  const finalEmpresaId = empresaId || family?.empresaId
  const [loginRedirect, setLoginRedirect] = useState(false)

  const MaybeReactQueryDevTools = () => {
    const isReactQueryDebuggingOn = process.env.REACT_APP_QUERY_DEBUG
    return isReactQueryDebuggingOn === 'true' ? <ReactQueryDevtools /> : <></>
  }

  const ClearCacheContainer = () => {
    useClearCacheEasterEgg()
    return <></>
  }

  useEffect(() => {
    if (authenticated && valueJwt) {
      // Os dados só são salvos no localStorage por retrocompatibilidade
      // com os componentes antigos. Os novos devem usar o authContext.
      if (finalEmpresaId) setEmpresaId(String(finalEmpresaId))
      if (familiaId || storeFamiliaId) setFamiliaId(String(familiaId || storeFamiliaId))
      if (tipoUsuario) setTipoUsuario(tipoUsuario)
      if (usuarioEmail) setUsuarioEmail(usuarioEmail)
      if (usuarioId) setUsuarioId(String(usuarioId))
      setLoginRedirect(true)
    }
  }, [
    valueJwt,
    authenticated,
    empresaId,
    familiaId,
    family,
    finalEmpresaId,
    pessoaId,
    planejadorId,
    setEmpresaId,
    setFamiliaId,
    setTipoUsuario,
    setUsuarioEmail,
    setUsuarioId,
    storeFamiliaId,
    theme,
    tipoUsuario,
    usuarioEmail,
    usuarioId
  ])

  return (
    <CoreContext.Provider
      value={{
        authenticated,
        empresaId: finalEmpresaId,
        familiaId: finalFamiliaId,
        planejadorId,
        tipoUsuario,
        usuarioId,
        userType: {
          isPlanner,
          isPlanned,
          isManager,
          isSupport
        },
        setEmpresaId,
        setFamiliaId,
        setTipoUsuario,
        setUsuarioEmail,
        setUsuarioId,
        usuarioEmailSetado,
        tipoUsuarioSetado,
        userId,
        loginRedirect,
        pessoaId: pessoaId?.toString() || null
      }}
    >
      <ThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <LoadingScreenProvider>
            <InitialDataContextProvider>
              <SelectComboContextProvider>
                <TutorialsProvider>
                  <TransactionContextProvider>
                    <DashboardProvider>
                      {children}
                      <LoadingScreen />
                      <ThemeModal />
                      <MaybeReactQueryDevTools />
                      <ClearCacheContainer />
                    </DashboardProvider>
                  </TransactionContextProvider>
                </TutorialsProvider>
              </SelectComboContextProvider>
            </InitialDataContextProvider>
          </LoadingScreenProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </CoreContext.Provider>
  )
}

export const useCoreContext = () => {
  const context = useContext(CoreContext)
  if (!context) {
    throw new Error('CoreContext ===> Need Wrap User container')
  }
  return context
}
