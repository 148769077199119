import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import styled from 'styled-components'
import { FilterIcon, SearchIcon } from '../../Icons'
import ListOfNotes from './ListOfNotes'
import RadioButton from '../../RadioButton'
import { Note } from '../../../domain/Notes/Notes'
import CreateNoteImg from '../../../assets/img/createNotesImg.svg'
import { Button } from '../../Buttons'
import ViewNote from './ViewNote'
import StarIcon from '../../Icons/StarIcon'
import { useCurrentFamilyQuery } from '../../../queries/family/queries'

const ContainerAll = styled.div`
  transition: all 0.3s linear;
  max-width: 1200px;
  width: 88vw;
  height: 72vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 16px;
  color: black;

  @media (min-width: 1360px) {
    width: 80vw;
  }
  @media (min-width: 1712px) {
    width: 72vw;
  }
`

const ContainerLeft = styled.div`
  border-right: solid 2px #64646480;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s ease-in-out;
  width: 50%;
`

const ContainerRight = styled.div`
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border: solid 4px ${({ theme }) => theme.colors.primary}64;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  width: 100%;
`

const TitleAndFilter = styled.div`
  transition: all 0.3s ease-in-out;
  padding: 16px 16px 16px 24px;
  border-bottom: solid 2px #64646480;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 88px;
`

const Filters = styled.div`
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
`

const ModalTitle = styled.h2`
  transition: all 0.3s ease-in-out;
  line-height: 1;
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  gap: 8px;
`

const FilterButton = styled.button<{ open: boolean }>`
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  background: none;
  width: 32px;
  height: 32px;
  padding: 8px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border-radius: 8px;
  ${({ open }) => (open ? 'background: #64646416;' : '')}

  &:hover {
    background: #64646424;
  }
`

const ContainerStarIcon = styled.div`
  transition: all 0.3s ease-in-out;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: #64646424;
  }
`

const SearchInput = styled.input`
  flex: 1;
  background: #b0b0b016;
  padding: 6px;
  margin: 0 16px;
  color: #484848;
  border-radius: 8px;
  border: 1px solid #aaa;
  font-size: 14px;
  cursor: text;
`

const ContainerCreateNote = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

const ImgCreateNote = styled.img`
  width: 56%;
`

const H4 = styled.h4`
  line-height: 1.4;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
`

const ContainerNewNote = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FamilyName = styled.span`
  font-size: 0.72rem;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee8;
  align-self: center;
  margin-top: 0.3rem;
  flex: 1;
  line-height: 1.2;
  font-weight: 600;
`

type WhatIsOpen = 'home' | 'view' | 'create'

interface Props {
  open: boolean
  onClose: () => void
}

export default ({ open, onClose }: Props) => {
  const [showFilter, setShowFilter] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [favoritesOnly, setFavoritesOnly] = useState(false)
  const [lastOrOlder, setLastOrOlder] = useState('last')
  const [filter, setFilter] = useState('')
  const [selectedItem, setSelectedItem] = useState<Note | undefined>()
  const [isNewNote, setIsNewNote] = useState(false)
  const [whatIsOpen, setWhatIsOpen] = useState<WhatIsOpen>('home')

  useEffect(() => {
    if (selectedItem !== undefined && !isNewNote) setWhatIsOpen('view')
    if (selectedItem === undefined && !isNewNote) setWhatIsOpen('home')
    if (isNewNote) {
      setSelectedItem(undefined)
      setWhatIsOpen('create')
    }
  }, [selectedItem, setSelectedItem, isNewNote])

  const { family } = useCurrentFamilyQuery()

  const handleShowFilter = () => {
    setShowSearch(false)
    setShowFilter(!showFilter)
  }
  const handleShowSearch = () => {
    setShowFilter(false)
    setShowSearch(!showSearch)
  }

  return (
    <Modal padding="0px" top={3.5} open={open} onClose={onClose} id="modal-notes">
      <ContainerAll>
        <ContainerLeft>
          <TitleAndFilter>
            <ModalTitle>
              Notas
              {!showFilter && <FamilyName> {family ? family.nome : 'Planejador'} </FamilyName>}
            </ModalTitle>
            {showFilter && (
              <RadioButton
                name="notesOrder"
                onChangeState={setLastOrOlder}
                items={[
                  { id: 'last', text: 'Mais recentes' },
                  { id: 'older', text: 'Mais antigas' }
                ]}
              />
            )}
            {showSearch && <SearchInput value={filter} onChange={(e) => setFilter(e.target.value)} />}
            <Filters>
              <FilterButton open={showFilter} onClick={handleShowFilter}>
                <FilterIcon />
              </FilterButton>
              <FilterButton open={showSearch} onClick={handleShowSearch}>
                <SearchIcon />
              </FilterButton>
              <ContainerStarIcon onClick={() => setFavoritesOnly(!favoritesOnly)}>
                <StarIcon fill={favoritesOnly} />
              </ContainerStarIcon>
            </Filters>
          </TitleAndFilter>
          <ListOfNotes
            isOpen={open}
            onNoteSelection={setSelectedItem}
            selectedNote={selectedItem}
            desc={lastOrOlder === 'last'}
            search={filter}
            favorite={favoritesOnly}
          />
        </ContainerLeft>

        <ContainerRight>
          {whatIsOpen === 'view' && (
            <ViewNote selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
          )}
          {whatIsOpen === 'home' && (
            <ContainerCreateNote>
              <ImgCreateNote src={CreateNoteImg} alt="" />
              <H4>{'Crie anotações para ajudar a reorganizar suas finanças'}</H4>
              <Button text={'Adicionar anotação'} onClick={() => setIsNewNote(true)} />
            </ContainerCreateNote>
          )}
          {whatIsOpen === 'create' && (
            <ContainerNewNote>
              <ViewNote isNewNote={true} setIsNewNote={setIsNewNote} />
            </ContainerNewNote>
          )}
        </ContainerRight>
      </ContainerAll>
    </Modal>
  )
}
