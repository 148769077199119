import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { allowedTypes, IFile, ISideBarEditor } from './types'
import { lightenDarkenColor } from './utils'
import { useTheme } from '../../../../hooks'
import { SelectColorSeletor } from './selectColorSelector'

export const SidebarEditor = ({
  className,
  onLogoChange,
  onPrimaryColorChange,
  onPrimaryDarkenColorChange,
  onSecondaryColorChange,
  onSecondaryDarkenColorChange
}: ISideBarEditor) => {
  const { theme } = useTheme()
  const [primaryColor, setPrimaryColor] = useState<string>(theme.colors.primary)
  const [primaryDarken, setPrimaryDarken] = useState<string>(theme.colors.primaryDarker)
  const [secondaryColor, setSecondaryColor] = useState<string>(theme.colors.secondary)
  const [secondaryDarken, setSecondaryDarken] = useState<string>(theme.colors.secondaryDarker)
  const [fileMessage, setFileMessage] = useState('Selecione o seu logo')
  const allowedFileTypes = allowedTypes
  const [logo, setLogo] = useState('')

  const handleChangeFile = (event) => {
    const files = event?.target?.files as IFile[]

    if (files.length === 0) {
      setFileMessage('Selecione o seu logo')
      return
    }

    const allAllowed = Array.from(files).every((file) => allowedFileTypes.includes(file?.type))

    if (!allAllowed) {
      setFileMessage('Please choose image files only.')
      return
    }

    const fileUrl = URL.createObjectURL(files[0] as unknown as Blob | MediaSource)
    setLogo(fileUrl)
    onLogoChange({
      logo: fileUrl
    })
    setFileMessage('Pronto!')
  }

  useEffect(() => {
    if (primaryColor && !primaryDarken) {
      const primaryDarken = lightenDarkenColor(primaryColor, -50)
      setPrimaryDarken(primaryDarken)
    }
  }, [primaryColor, primaryDarken])

  const [currentPicker, setCurrentPicker] = useState<string>()

  return (
    <S.Container className={className}>
      <S.Field>
        <label className="bold">Sua marca</label>

        <S.SelectImage>
          {!logo ? (
            <>
              <S.SelectImageIcon className="fas fa-image" />
              <span>{fileMessage}</span>
            </>
          ) : (
            <S.LogoImage src={logo} />
          )}
          <input type="file" onChange={handleChangeFile} accept={allowedFileTypes.join(',')} />
        </S.SelectImage>
      </S.Field>

      <SelectColorSeletor
        id="primaryColor"
        title="Cor Primária"
        color={primaryColor}
        setColor={setPrimaryColor}
        onColorChange={onPrimaryColorChange}
        onOpen={(id) => setCurrentPicker(id)}
        current={currentPicker}
      />

      <SelectColorSeletor
        id="primaryDarkenColor"
        title="Cor Primária (escura)"
        color={primaryDarken}
        setColor={setPrimaryDarken}
        onColorChange={onPrimaryDarkenColorChange}
        onOpen={(id) => setCurrentPicker(id)}
        current={currentPicker}
      />

      <SelectColorSeletor
        id="secondaryColor"
        title="Cor Secundária"
        color={secondaryColor}
        setColor={setSecondaryColor}
        onColorChange={onSecondaryColorChange}
        onOpen={(id) => setCurrentPicker(id)}
        current={currentPicker}
      />

      <SelectColorSeletor
        id="secondaryDarkenColor"
        title="Cor Secundária (escura)"
        color={secondaryDarken}
        setColor={setSecondaryDarken}
        onColorChange={onSecondaryDarkenColorChange}
        onOpen={(id) => setCurrentPicker(id)}
        current={currentPicker}
      />
    </S.Container>
  )
}
