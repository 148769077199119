import React, { useState } from 'react'
import { ErrorContainer, Footer } from './styles'
import { Button } from '../../Buttons'
import { getLoadingInvestmentsByUserId } from '../../../domain/investments'
import { useNumericLocalStorage } from '../../../hooks'
import { noticeError } from '../../../services/Monitoring'
import Table from '../Table'
import { usePersonalByUserIdQuery } from '../../../queries/user/queries'
import { ContainerTable } from '../Table.styles'
import { TableFooter } from '../Footer'
import { Text } from '../Footer/Footer.styles'
import { formatMonetaryValue } from '../../../domain/formatter'
// import { TransactionsByInvestmentIdTable } from '../TransactionsByInvestmentIdTable'
import { useColumns } from './Columns'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { IEquityDelete, IEquityInvestmentsByUserIdTable } from './types'
// import { TransactionsByManualInvestmentTable } from '../TransactionsByManualInvestmentTable'
// import { IAllInvestment } from '../../../domain/investments/api/ManualInvestments/types'
// import { IFixedEquity } from '../../Modal/FixedEquityModal/types'
import { DeleteInvestmentModal } from '../../Modal'
import { EquitysTable } from './Equitys'

const loadingData = getLoadingInvestmentsByUserId()

export const EquityInvestmentsByUserIdTable = ({
  familyId,
  groupedInvestments,
  isError,
  isLoading,
  refetchEquityInvestments,
  totalAmount,
  totalAmountOriginal,
  transactionsEndDate,
  transactionsStartDate,
  userId,
  widthBeforeHorizontalScroll,
  fixedEquityModalMethods
}: IEquityInvestmentsByUserIdTable) => {
  const token = useRequiredAuth()
  const columns = useColumns()
  const [page, setPage] = useState(0)
  const downloadFileName = `Investimentos-${new Date().toISOString().split('T')[0]}`
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const { personalByUserId } = usePersonalByUserIdQuery({ userId })
  const [rowDeleted, setRowDeleted] = useState<IEquityDelete>()
  const userFullName = `${personalByUserId?.firstName} ${personalByUserId?.lastName}`
  const { value: pageSize, setValue: setPageSize } = useNumericLocalStorage(
    'investments-by-userid-page-size',
    10
  )
  const totalPages = groupedInvestments ? Math.ceil(groupedInvestments.length / pageSize) : 1
  const handleRefetch = () => refetchEquityInvestments?.()

  if (isError) {
    noticeError(undefined, { token })
    return (
      <ErrorContainer>
        <br /> Erro ao carregar tabela :c <br /> Tente novamente: <br />
        <Button text="Tentar novamente." onClick={() => refetchEquityInvestments?.()} />
      </ErrorContainer>
    )
  }

  // console.log({ groupedInvestments })

  return (
    <>
      <ContainerTable>
        <Table
          columns={columns}
          data={groupedInvestments || []}
          downloadFileName={downloadFileName}
          filterEnabled={false}
          isLoading={isLoading}
          loadingData={loadingData}
          onNextPage={() => setPage(page + 1)}
          onPageSizeChange={setPageSize}
          onPreviousPage={() => setPage(page - 1)}
          pageable={true}
          pageIndex={page}
          pageSize={pageSize}
          title={`Investimentos: ${personalByUserId ? userFullName : 'Carregando...'}`}
          totalPages={totalPages}
          renderExpanded={({ type, investments }) => {
            if (!type) return <></>
            return (
              <EquitysTable
                type={type}
                investments={investments}
                fixedEquityModalMethods={fixedEquityModalMethods}
                handleRefetch={handleRefetch}
                transactionsEndDate={transactionsEndDate}
                transactionsStartDate={transactionsStartDate}
                familyId={familyId}
                userId={userId}
                widthBeforeHorizontalScroll={widthBeforeHorizontalScroll}
                setRowDeleted={setRowDeleted}
                setDeleteModalOpen={setDeleteModalOpen}
              />
              // <ExpandedContainer>
              //   <Table
              //     onRowClick={handleRowClick}
              //     title=" "
              //     data={investments || []}
              //     columns={COLUMNS_MAP?.[type]}
              //     filterEnabled={false}
              //     toggledOffByDefaultColumns={[
              //       'quantityQuota',
              //       'amountQuota',
              //       'unitPriceQuota',
              //       'dateQuota',
              //       'taxes'
              //     ]}
              //     sortable={true}
              //     // onSort={(columnId, order) => onSort({ columnId, order, columnLevelTwo: columnId })}
              //     downloadFileName={`Investimentos-${type}-${new Date().toISOString().split('T')[0]}`}
              //     renderExpanded={({ id, isManual }) => (
              //       <ExpandedContainer>
              //         <TransactionsByManualInvestmentTable
              //           familyId={familyId}
              //           investmentId={id}
              //           start={transactionsStartDate}
              //           end={transactionsEndDate}
              //           toggledOffByDefaultColumns={[
              //             'quantityQuota',
              //             'amountQuota',
              //             'unitPriceQuota',
              //             'dateQuota',
              //             'taxes'
              //           ]}
              //           isManualInvestment={isManual}
              //         />
              //         {/* {!isManual ? (
              //         <TransactionsByInvestmentIdTable
              //           familyId={familyId}
              //           pluggyInvestmentId={id}
              //           start={transactionsStartDate}
              //           end={transactionsEndDate}
              //           toggledOffByDefaultColumns={[
              //             'quantityQuota',
              //             'amountQuota',
              //             'unitPriceQuota',
              //             'dateQuota',
              //             'taxes'
              //           ]}
              //         />
              //       ) : (
              //         <TransactionsByManualInvestmentTable
              //           familyId={familyId}
              //           investmentId={id}
              //           start={transactionsStartDate}
              //           end={transactionsEndDate}
              //           toggledOffByDefaultColumns={[
              //             'quantityQuota',
              //             'amountQuota',
              //             'unitPriceQuota',
              //             'dateQuota',
              //             'taxes'
              //           ]}
              //         />
              //       )} */}
              //       </ExpandedContainer>
              //     )}
              //     widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
              //   />
              // </ExpandedContainer>
            )
          }}
          renderFooter={() => (
            <TableFooter>
              <Footer>
                <Text />
                <Text />
                <Text>
                  Valor total aplicado: <br />
                  R$ {formatMonetaryValue(totalAmountOriginal || 0)}
                </Text>
                <Text>
                  Valor total atual: <br />
                  R$ {formatMonetaryValue(totalAmount || 0)}
                </Text>
                <Text />
              </Footer>
            </TableFooter>
          )}
          widthBeforeHorizontalScroll={widthBeforeHorizontalScroll || 1382}
        />
      </ContainerTable>

      {isDeleteModalOpen && rowDeleted && (
        <DeleteInvestmentModal
          open={isDeleteModalOpen}
          investmentId={rowDeleted?.id}
          investmentName={rowDeleted?.name}
          onClose={() => setDeleteModalOpen(false)}
          isManual={rowDeleted?.isManual}
          isTransaction={rowDeleted?.isTransaction}
          transactionId={rowDeleted?.transactionId}
          onDeletion={() => {
            // handleDelete?.()
            setDeleteModalOpen(false)
          }}
        />
      )}
    </>
  )
}
