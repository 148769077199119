import { useState } from 'react'
import { Button } from '../../../components/Buttons'
import { PageFirstRow } from '../../../components/Headers'
import { WhitelabelEditor } from '../WhitelabelEditor'
import * as S from './styles'

export const Whitelabel = () => {
  const [editorOpen, setEditorOpen] = useState(false)
  return (
    <S.Container>
      <PageFirstRow title="Whitelabel"></PageFirstRow>

      <S.FeaturedContent>
        <h4>
          Configure facilmente seu Whitelabel ajustando cores primárias e secundárias, logo, nome da marca,
          subdomínio e e-mails relacionados.
        </h4>

        <p>
          Todo o processo é realizado diretamente por você, garantindo autonomia para futuras edições. Na
          primeira configuração, sua personalização será submetida para aprovação pelo time de Customer
          Success, assegurando qualidade e alinhamento.
        </p>

        <p>Transforme a experiência do seu cliente em um ambiente único e sob medida!</p>

        <p>
          <Button float={false} onClick={() => setEditorOpen(true)} text="Criar whitelabel" />
        </p>
      </S.FeaturedContent>

      <WhitelabelEditor open={editorOpen} onClose={() => setEditorOpen(false)} />
    </S.Container>
  )
}
