import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: calc(100vh - 82px); */
  min-height: 400px;
  width: 100%;
  /* background-color: #f8f8f8; */

  h3 {
    font-weight: bold;
  }
`

export const Box = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 50%;
  min-width: 320px;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  gap: 16px;
`
