import React, { useState } from 'react'
import * as S from './styles'
// import { PageFirstRow } from '../../../components/Headers'
import { useTheme } from '../../../hooks'
import { PagePreview } from './Preview'

export const WhitelabelEditor = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { theme } = useTheme()
  const [logo, setLogo] = useState<string | undefined>(theme.externalUrlLogo)
  const [primaryColor, setPrimaryColor] = useState(theme.colors.primary)
  const [primaryDarkenColor, setPrimaryDarkenColor] = useState(theme.colors.primaryDarker)
  const [secondaryColor, setSecondaryColor] = useState(theme.colors.secondary)
  const [secondaryDarkenColor, setSecondaryDarkenColor] = useState(theme.colors.secondaryDarker)

  const colors = {
    primary: primaryColor,
    secondary: secondaryColor,
    primaryDarken: primaryDarkenColor,
    secondaryDarken: secondaryDarkenColor
  }

  console.log({ theme })

  return (
    <S.Dialog open={open}>
      <S.Container>
        <S.ButtonClose onClick={() => onClose()}>X</S.ButtonClose>

        <S.Sidebar
          onLogoChange={({ logo }) => setLogo(logo)}
          onPrimaryColorChange={(color: string) => setPrimaryColor(color)}
          onPrimaryDarkenColorChange={(color: string) => setPrimaryDarkenColor(color)}
          onSecondaryColorChange={(color: string) => setSecondaryColor(color)}
          onSecondaryDarkenColorChange={(color: string) => setSecondaryDarkenColor(color)}
        />

        <S.GridContainer>
          <PagePreview logo={logo} role={2} colors={colors} />
          <PagePreview logo={logo} role={1} colors={colors} />
        </S.GridContainer>
      </S.Container>
    </S.Dialog>
  )
}
