import React from 'react'
import Select from 'react-select'
import { Redirect, Link } from 'react-router-dom'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import $ from 'jquery'
import { confirmAlert } from 'react-confirm-alert'
import SkyLight from 'react-skylight'
import CurrencyInput from 'react-currency-input'
import { CircularProgress, Typography } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Col, Row, Card, CardHeader, NavLink, NavItem } from 'reactstrap'
import WrapperForButtonBorder from '../../theme/wrapperForButtonBorder'
import WrapperForModal from '../../theme/wrapperForModal'
import { ThemeContext } from '../../hooks/contexts/themeContext'
import { Maintenance } from '../../components/Maintenance'
import { withContexts } from '../../components'

const muiTheme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f8f9fe'
        }
      }
    },
    MUIDataTableSelectCell: {
      fixedHeaderCommon: {
        backgroundColor: 'unset'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'unset',
        borderTop: 'none',
        padding: '16px 10px'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeaderYAxis: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflow: 'hidden'
      }
    },
    MuiToolbar: {
      regular: {
        backgroundColor: '#e4eaf4'
      }
    },
    MUIDataTable: {
      responsiveStacked: {
        overflow: 'hidden'
      }
    },
    MUIDataTableBodyCell: {
      stackedCommon: {
        height: 'fit-content!important'
      }
    }
  }
})

class DestinacaoInvestimento extends React.Component {
  static contextType = ThemeContext

  moment = require('moment')

  state = {
    patrimonio: [],
    destinacao: [],
    plano: [],
    item: {},
    valorDestinadoPatrimonioNaoFinanceiro: {},
    valorDestinadoPorPlano: {},
    submittingDestinationForm: false
  }

  componentDidMount() {
    this.setState(
      {
        empresaId: this.props.empresaId,
        familiaId: this.props.familiaId,
        planejadorId: this.props.planejadorId
      },
      function () {
        if (!this.props.authenticated) {
          console.info(`Not authenticated, redirecting to login`)
          this.setRedirect()
          return
        }

        this.atualizarPatrimonio()
        this.atualizarDestinacoes()
        this.atualizarPlanos()
        this.atualizarPlanosEParcelas()
      }
    )
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/auth/login" />
    }
  }

  montarDropDown = (itens, id, changeMethod, selectedValue) => (
    <Select
      id={id}
      onChange={changeMethod}
      className="select-component"
      options={itens}
      defaultValue={itens != null ? itens[0] : ''}
      placeholder="Selecione..."
      value={
        itens != null && selectedValue != null
          ? itens.find((op) => {
              if (op.value != null && selectedValue['id'] != null && op.value['id'] != null) {
                return op.value.id === selectedValue.id
              } else {
                return op.value != null && op.value === selectedValue
              }
            })
          : null
      }
    />
  )

  atualizarPlanos = () => {
    var url = global.server_api_new + '/sonho/planos/familia/' + this.state.familiaId + '/emAndamento'

    axios.get(url).then((res) => {
      var data = []
      var dataCombo = []

      if (res.data.results != null) {
        data = res.data.results
        data.forEach((mp) => {
          dataCombo.push({
            value: mp,
            label: mp.descricao
          })
        })
      }

      this.setState({ plano: data, planoCombo: dataCombo })
    })
  }

  atualizarPatrimonio = () => {
    //var url = global.server_api + 'api/patrimonio/familia/' + this.state.familiaId + '/filtro';
    var url = global.server_api_new + '/patrimonio/familia/' + this.state.familiaId + '/filtro'

    var currentScroll = [
      document.documentElement.scrollLeft || document.body.scrollLeft,
      document.documentElement.scrollTop || document.body.scrollTop
    ]

    global.spinnerShow($)

    axios.post(url, {}).then((res) => {
      var patrimonio = []
      var dataCombo = []

      if (res.data.results != null) {
        patrimonio = res.data.results.filter(function (item) {
          return item.tipoPatrimonio === 3
        })

        patrimonio.forEach((item, idx) => {
          dataCombo.push({
            value: item,
            label: item.descricao
          })

          this.buscarValorTotalEDestinadoDoPatrimonioFinanceiro(item.id)
        })
      }

      this.setState({ patrimonio: patrimonio, patrimonioCombo: dataCombo }, function () {
        global.spinnerHide($, currentScroll)
      })
    })
  }

  atualizarDestinacoes = () => {
    //var url = global.server_api + 'api/patrimonio/destinacao/familia/' + this.state.familiaId;
    var url = global.server_api_new + '/patrimonio/v2/destinacao/familia/' + this.state.familiaId

    var currentScroll = [
      document.documentElement.scrollLeft || document.body.scrollLeft,
      document.documentElement.scrollTop || document.body.scrollTop
    ]

    global.spinnerShow($)

    axios.get(url).then((res) => {
      var destinacao = []

      if (res.data.results != null) {
        destinacao = res.data.results
      }

      this.setState({ destinacao: destinacao }, function () {
        global.spinnerHide($, currentScroll)
      })
    })
  }

  atualizarPlanosEParcelas = () => {
    var familiaId = this.props.familiaId
    //var url = global.server_api + 'api/dashboard/orcamento-no-periodo/' + familiaId;
    var url = global.server_api_new + '/dashboard/orcamento-no-periodo/' + familiaId

    var filtro = {
      DataInicial: this.moment().startOf('month').toDate(),
      DataFinal: this.moment().endOf('month').toDate(),
      OrcamentoId: -1
    }

    axios.post(url, filtro).then((res) => {
      if (res.data.results != null) {
        this.setState({ planos: res.data.results })
      }
    })
  }

  buscarValorTotalEDestinadoDoPatrimonioFinanceiro = (patrimonioId) => {
    //var url = global.server_api + 'api/patrimonio/destinacao/familia/' + this.state.familiaId + '/compras/' + patrimonioId;
    var url =
      global.server_api_new +
      '/patrimonio/destinacao/familia/' +
      this.state.familiaId +
      '/compras/' +
      patrimonioId

    axios.get(url).then((res) => {
      if (res.data.success === true) {
        if (res.data.singleResult == null) {
          console.log('Não veio nenhum resultado')
          return
        }

        var patrimonio = this.state.patrimonio

        var rowIndex = -1

        var patrimonioFiltrado = this.state.patrimonio.filter(function (it, index) {
          var result = it.id === patrimonioId

          if (result) {
            rowIndex = index
          }

          return result
        })

        if (
          patrimonio != null &&
          patrimonio[rowIndex] != null &&
          patrimonioFiltrado != null &&
          patrimonioFiltrado.length === 1
        ) {
          if (patrimonio[rowIndex].id !== patrimonioId) {
            console.log(
              'Pegamos o rowIndex errado para o patrimonioId',
              patrimonioId,
              patrimonio[rowIndex].id,
              rowIndex
            )
          } else {
            patrimonio[rowIndex].valorAplicado =
              res.data.singleResult.totalPatrimonio != null ? res.data.singleResult.totalPatrimonio : 0

            patrimonio[rowIndex].valorAtual =
              res.data.singleResult.totalPatrimonioCotizado != null
                ? res.data.singleResult.totalPatrimonioCotizado
                : 0

            patrimonio[rowIndex].valorDestinado =
              res.data.singleResult.totalDestinado != null ? res.data.singleResult.totalDestinado : 0

            patrimonio[rowIndex].destinacaoPorPlano =
              res.data.singleResult.destinacaoAtual != null ? res.data.singleResult.destinacaoAtual : []

            var valorDestinadoPorPlano = this.state.valorDestinadoPorPlano

            if (res.data.singleResult.destinacaoAtual != null) {
              res.data.singleResult.destinacaoAtual.forEach((it) => {
                var valor = valorDestinadoPorPlano[it.planoId]

                if (valor == null) valor = 0

                valor += it.valorDestinado

                valorDestinadoPorPlano[it.planoId] = valor
              })
            }

            this.setState(
              {
                patrimonio: patrimonio,
                valorDestinadoPorPlano: valorDestinadoPorPlano
              },
              function () {
                //console.log('Patrimonio Atualizado com Destinacoes', patrimonioId, rowIndex, res.data.singleResult.destinacaoAtual);
              }
            )
          }
        }
      } else {
        console.log(res.data.exception)
      }
    })
  }

  salvar = () => {
    //var url = global.server_api + 'api/patrimonio/destinacaoInvestimento';
    var url = global.server_api_new + '/patrimonio/destinacaoInvestimento'

    var currentScroll = [
      document.documentElement.scrollLeft || document.body.scrollLeft,
      document.documentElement.scrollTop || document.body.scrollTop
    ]

    global.spinnerShow($)

    var item = this.state.item

    item.empresaId = this.state.empresaId
    item.familiaId = this.state.familiaId

    delete item.patrimonio
    delete item.plano

    if (item.id == null || item.id === '') {
      delete item.id
    }

    const _this = this
    axios.post(url, item).then((res) => {
      console.log('salvar - resposta', res.data)

      global.spinnerHide($, currentScroll)

      if (res.data.success === true) {
        window.location.reload()
      } else {
        console.log(res.data.exception)
        const options = {
          title: 'Erro ao aplicar mudanças na destinação',
          message: res.data.exception.Message,
          buttons: [
            {
              label: 'Ok'
            }
          ]
        }

        _this.setState({
          submittingDestinationForm: false
        })
        confirmAlert(options)
      }
    })
  }

  confirmaExcluir = (patrimonioPlanoId) => {
    const options = {
      title: 'Exclusão de Destinação',
      message: 'Tem certeza que deseja excluir? Este procedimento é irreversível!',
      buttons: [
        {
          label: 'Sim',
          onClick: () => this.excluir(patrimonioPlanoId)
        },
        {
          label: 'Não'
        }
      ]
    }

    confirmAlert(options)
  }

  excluir = (patrimonioPlanoId) => {
    //var url = global.server_api + 'api/patrimonio/destinacao/' + patrimonioPlanoId;
    var url = global.server_api_new + '/patrimonio/destinacao/' + patrimonioPlanoId

    axios.delete(url, {}).then((res) => {
      const result = res.data

      if (result.success === true) {
        window.location.reload()
      } else {
        console.log(res.data.exception)
        alert('Erro ao remover destinação. ' + res.data.exception.Message)
      }
    })
  }

  mostrarPatrimonios = () => {
    if (this.state.patrimonio == null || this.state.patrimonio.length === 0) {
      return <Col>Nenhum patrimônio cadastrado ainda.</Col>
    } else {
      const columns = [
        {
          name: 'id',
          label: 'id',
          options: {
            display: false,
            viewColumns: false,
            filter: false
          }
        },
        {
          name: 'descricao',
          label: 'Descrição',
          options: {
            display: true,
            viewColumns: true,
            filter: false
          }
        },
        {
          name: 'valorAplicado',
          label: 'Valor Aplicado',
          options: {
            display: true,
            viewColumns: true,
            filter: false,
            customBodyRender: (value) => {
              return (
                <>
                  {value != null
                    ? value
                        .toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                        .replace('R$', '')
                    : '-'}
                </>
              )
            }
          }
        },
        {
          name: 'valorAtual',
          label: 'Valor Atual',
          options: {
            display: true,
            viewColumns: true,
            filter: false,
            customBodyRender: (value) => {
              return (
                <>
                  {value != null
                    ? value
                        .toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                        .replace('R$', '')
                    : '-'}
                </>
              )
            }
          }
        },
        {
          name: 'percentualDestinado',
          label: 'Percentual destinado',
          options: {
            display: true,
            viewColumns: true,
            filter: false,
            customBodyRender: (value, tableMeta) => {
              var patrimonioId = tableMeta.rowData[0]
              var destinacoesDoPatrimonio
              var percentualTotalDestinado = 0

              destinacoesDoPatrimonio = this.state.destinacao.filter(function (item, index) {
                if (item.patrimonio) {
                  var result = item.patrimonio.id === patrimonioId
                  return result
                }
              })

              destinacoesDoPatrimonio.forEach((destinacao) => {
                percentualTotalDestinado += destinacao.percentualDestinado
              })

              return (
                <div style={{ marginLeft: 10 }}>
                  {percentualTotalDestinado > 0
                    ? `${parseFloat(percentualTotalDestinado.toFixed(2))} %`
                    : '-'}
                </div>
              )
            }
          }
        },
        {
          name: 'tipoPatrimonio',
          label: 'Patrimônio',
          options: {
            display: false,
            viewColumns: true,
            filter: false,
            customBodyRender: (value) => {
              return <>{value != null ? global.tipoPatrimonio[value] : ''}</>
            }
          }
        },
        {
          name: 'tipoPatrimonio',
          label: 'Nova Destinação',
          options: {
            display: true,
            viewColumns: true,
            filter: false,
            customBodyRender: (value, tableMeta) => {
              var patrimonioId = tableMeta.rowData[0]

              var patrimonioFiltrado = this.state.patrimonio.filter(function (it, index) {
                var result = it.id === patrimonioId
                return result
              })

              var patrimonio = null

              if (patrimonioFiltrado != null && patrimonioFiltrado.length > 0) {
                patrimonio = patrimonioFiltrado[0]
              }

              return (
                <div style={{ cursor: 'pointer', textAlign: 'left' }}>
                  <div
                    onClick={(e) => {
                      this.setItemOnState('patrimonio', patrimonio)
                      this.setItemOnState('patrimonioId', patrimonioId)
                      global.showModal(this.modal)
                    }}
                    style={{
                      padding: '0.5rem 0.5rem',
                      float: 'left',
                      color: 'white',
                      borderRadius: '30px',
                      background: '#2bacc2',
                      fontWeight: '100',
                      minWidth: '90px'
                    }}
                  >
                    <i style={{ marginRight: '.45rem', fontSize: '.6rem' }} className="fas fa-plus"></i>
                    Adicionar
                  </div>
                </div>
              )
            }
          }
        }
      ]
      const options = {
        selectableRows: false,
        expandableRows: true,
        filter: true,
        filterType: 'dropdown',
        responsive: 'stacked',
        print: false,
        download: false,
        serverSide: true,
        textLabels: global.textLabels,
        pagination: false,
        renderExpandableRow: (rowData, rowMeta) => {
          return this.mostrarDestinacoes(rowData[0])
        }
      }
      var data = []
      if (this.state.patrimonio != null) {
        data = this.state.patrimonio
      }
      return (
        <div style={{ width: '100%' }}>
          <MuiThemeProvider theme={muiTheme}>
            <MUIDataTable
              id="orcamento-table"
              title={
                <>
                  <h2 style={{ paddingTop: 15, paddingBottom: 10 }}>
                    Destine seus novos investimentos para os seus objetivos
                  </h2>
                  <Typography>
                    {false && (
                      <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                    )}
                  </Typography>
                </>
              }
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      )
    }
  }

  mostrarDestinacoes = (patrimonioId) => {
    var destinacaoTemp = this.state.destinacao.filter(function (item) {
      return item.patrimonio.id === patrimonioId
    })

    if (destinacaoTemp == null || destinacaoTemp.length === 0) {
      return (
        <TableRow style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
          <TableCell colSpan="6" style={{ paddingLeft: '3rem', background: '#FFF' }}>
            <div>Nenhuma destinação de investimento ainda.</div>
          </TableCell>
        </TableRow>
      )
    } else {
      return destinacaoTemp.map((item, key) => {
        return (
          <TableRow key={key} style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableCell colSpan="7" style={{ paddingLeft: '3rem', background: '#FFF' }}>
              <div style={{ width: '100%' }}>
                <div style={{ width: '15px', display: 'inline-block' }}>
                  <div onClick={() => this.confirmaExcluir(item.id)}>
                    <i className="far fa-trash-alt"></i>
                  </div>
                </div>
                <div style={{ width: '38%', display: 'inline-block' }}>
                  {item?.plano?.sonho?.descricao || 'Referência do plano perdida'}
                </div>
                <div style={{ width: '45%', display: 'inline-block' }}>{item.percentualDestinado} %</div>
              </div>
            </TableCell>
          </TableRow>
        )
      })
    }
  }

  setItemOnState = (key, value, callback) => {
    var item = this.state.item

    item[key] = value

    if (callback) this.setState({ item: item }, callback)
    else this.setState({ item: item })
  }

  mudandoPlano = (selectedOption) => {
    const plano = selectedOption.value
    this.setItemOnState('plano', plano)
    this.setItemOnState('planoId', plano != null ? plano.planoId : null)
    this.verificarDestinacaoExistente(plano.planoId, this.state.item.patrimonioId)
  }

  mudandoPatrimonio = (selectedOption) => {
    const patrimonio = selectedOption.value
    this.setItemOnState('patrimonio', patrimonio)
    this.setItemOnState('patrimonioId', patrimonio != null ? patrimonio.id : null)
    this.verificarDestinacaoExistente(this.state.item.planoId, patrimonio.id)
  }

  verificarDestinacaoExistente = (planoId, patrimonioId) => {
    if (planoId == null || patrimonioId == null) {
      this.setItemOnState('id', null)
      return
    }

    var encontrado = this.state.destinacao.find(function (item) {
      return item.patrimonio != null && item.patrimonio.id === patrimonioId && item.plano.id === planoId
    })

    if (encontrado != null) {
      if (encontrado.percentualDestinado != null && encontrado.percentualDestinado !== '') {
        this.setItemOnState('percentualDestinado', encontrado.percentualDestinado)
        this.setItemOnState(
          'percentualDestinadoFormatado',
          encontrado.percentualDestinado.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 10
          }) + '%'
        )
      }

      this.setItemOnState('id', encontrado.id)
    } else {
      this.setItemOnState('id', null)
    }
  }

  limparSelecao = () => {
    var item = {}
    this.setState({
      item: item
    })
  }

  mostrarPopup = () => (
    <WrapperForModal>
      <div className="planosSonhos">
        <SkyLight
          ref={(ref) => (this.modal = ref)}
          transitionDuration={0}
          beforeOpen={this.preCarregarParaEdicao}
          afterClose={this.limparSelecao}
          dialogStyles={{ borderRadius: '1rem', padding: '2rem' }}
          title={
            <>
              <div className="pop-up-title">
                <i className="ni ni-check-bold"></i>
                <h2>Cadastro de Destinação</h2>
              </div>
            </>
          }
        >
          <Row>
            <Col lg="4" xl="4">
              <label>Patrimômio *</label>
              {this.montarDropDown(
                this.state.patrimonioCombo,
                'patrimonio',
                this.mudandoPatrimonio,
                this.state.item.patrimonio
              )}
            </Col>
            <Col lg="4" xl="4">
              <label>Plano *</label>
              {this.montarDropDown(this.state.planoCombo, 'plano', this.mudandoPlano, this.state.item.plano)}
            </Col>
            <Col lg="4" xl="4">
              <div>
                <label>Percentual *</label>
                <CurrencyInput
                  value={
                    this.state.item.percentualDestinadoFormatado != null
                      ? this.state.item.percentualDestinadoFormatado
                      : this.state.item.percentualDestinado
                  }
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  suffix={'%'}
                  onChangeEvent={(event, formattedValue, value) => {
                    this.setItemOnState('percentualDestinado', value)
                    this.setItemOnState('percentualDestinadoFormatado', formattedValue)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" xl="12">
              <button
                onClick={() => {
                  this.setState({ submittingDestinationForm: true }, this.salvar)
                  // this.salvar()
                }}
                disabled={this.state.submittingDestinationForm}
                className="featured-button"
                style={{
                  float: 'right',
                  background: `linear-gradient(45deg, ${this.context.theme.colors.primary} 0%, ${this.context.theme.colors.secondary} 100%)`
                }}
              >
                Salvar
              </button>
            </Col>
          </Row>
        </SkyLight>
      </div>
    </WrapperForModal>
  )

  mostrarPlanos = () => {
    if (this.state.planos == null || this.state.planos.length === 0) {
      return false
    }

    const options = {
      selectableRows: false,
      expandableRows: false,
      expandableRowsOnClick: false,
      textLabels: global.textLabels,
      filter: false,
      filterType: 'dropdown',
      responsive: 'stacked',
      print: false,
      download: false,
      serverSide: false,
      pagination: false,
      viewColumns: false
    }

    const columns = [
      {
        name: 'nomeCategoria',
        label: 'Nome',
        options: {
          display: true,
          viewColumns: true,
          filter: false
        }
      },
      {
        name: 'estimado',
        label: 'Valor da parcela',
        options: {
          display: true,
          viewColumns: true,
          filter: false,
          customBodyRender: (value) => {
            var checkedValue = value
            if (value < 0) {
              checkedValue = 0 // para zerar eventuais parcelas negativas
            }
            return (
              <>
                {checkedValue != null
                  ? checkedValue
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                      .replace('R$', '')
                  : ''}
              </>
            )
          }
        }
      }
    ]

    return (
      <div>
        <MuiThemeProvider theme={muiTheme}>
          <MUIDataTable
            title={
              <>
                <h2 style={{ paddingTop: 15, paddingBottom: 10 }}>Planos</h2>
                <Typography>
                  {false && (
                    <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                  )}
                </Typography>
              </>
            }
            data={this.state.planos}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    )
  }

  render() {
    return (
      <div style={{ padding: '0 5%', marginTop: 20 }}>
        {this.renderRedirect()}
        {this.mostrarPopup()}
        <div className="home-summary-top">
          <Row>
            <Col lg="12" xl="12" style={{ marginBottom: '15px' }}>
              <div>
                <Card>
                  <CardHeader className="border-0" style={{ padding: '30px 15px 30px 0' }}>
                    <Row className="align-items-center">
                      <div className="col">
                        <img
                          src={require('../../assets/img/theme/icone-calendario.png')}
                          alt="Destinação de Patrimônio"
                          className="before-title-img"
                        />
                        <h3 className="mb-0 chart-title">Destinação de Investimento</h3>
                      </div>

                      <NavItem className="general-button dark with-left-margin" style={{ float: 'right' }}>
                        <WrapperForButtonBorder>
                          <NavLink
                            className="nav-link-icon aux-button button-border"
                            to="/admin/planos-sonhos"
                            tag={Link}
                          >
                            <span className="nav-link-inner--text">Planos & Sonhos</span>
                          </NavLink>
                        </WrapperForButtonBorder>
                      </NavItem>
                      <NavItem className="general-button dark with-left-margin" style={{ float: 'right' }}>
                        <WrapperForButtonBorder>
                          <NavLink
                            className="nav-link-icon aux-button button-border"
                            to="/admin/gestao-planos-sonhos"
                            tag={Link}
                          >
                            <span className="nav-link-inner--text inner">Gestão de Planos</span>
                          </NavLink>
                        </WrapperForButtonBorder>
                      </NavItem>
                      <NavItem className="general-button dark with-left-margin" style={{ float: 'right' }}>
                        <NavLink
                          className="nav-link-icon featured-button"
                          to="/admin/destinacao-patrimonio"
                          tag={Link}
                          style={{
                            backgroundImage: `linear-gradient(45deg, ${this.context.theme.colors.primary} 0%, ${this.context.theme.colors.secondary} 100%)`
                          }}
                        >
                          <span className="nav-link-inner--text inner">Destinação de Patrimônio</span>
                        </NavLink>
                      </NavItem>
                    </Row>
                  </CardHeader>
                </Card>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xl="8" lg="12">
            {this.mostrarPatrimonios()}
          </Col>
          <Col xl="4" lg="12">
            {this.mostrarPlanos()}
          </Col>
        </Row>

        {/* <Maintenance /> */}
      </div>
    )
  }
}

export default withContexts(DestinacaoInvestimento)
