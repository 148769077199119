import styled from 'styled-components'
import { SidebarEditor } from './SidebarEditor'

export const Dialog = styled.dialog`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`

export const Container = styled.div`
  background-color: #fff;
  border-radius: 25px;
  bottom: 0;
  display: flex;
  height: 92vh;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 95vw;
  z-index: 2;
  overflow: hidden;
`

export const ButtonClose = styled.button`
  align-items: center;
  background-color: violet;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
  z-index: 3;
`

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

export const GridContainer = styled.div`
  background: rgb(229, 229, 229);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3rem;
  padding: 4rem 3rem;
  overflow-y: auto;
`

export const LogoImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`

export const FlexRow = styled.div`
  display: flex;
`

export const Sidebar = styled(SidebarEditor)`
  /* overflow-y: auto; */
  height: 92vh;
`
