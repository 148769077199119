import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { SimplifiedCategoria } from '../../../domain/categoria/Categoria'
import { useGetFilteredCategories } from '../../../queries/categoria/queries'
import { useChangeLancamentoCategory } from '../../../queries/lancamento/mutations'
import { noticeError } from '../../../services/Monitoring'
import { CategoriaSelect } from '../../Selects'

const SelectContainer = styled.div`
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  min-width: 135px;
`

interface Props {
  selectedId: SimplifiedCategoria['id']
}

const Text = styled.span`
  text-align: center;
`

const CategoriaCell = ({ selectedId }: Props) => {
  const { category, isLoadingCategorias } = useGetFilteredCategories({ categoriaId: selectedId })

  const text = useCallback(() => {
    if (isLoadingCategorias) {
      return '...'
    }

    let name = 'Sem categoria'
    if (category?.nome && selectedId) {
      name = category?.nome
    }

    return name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCategorias, selectedId])

  return <Text>{text()}</Text>
}

export const CategoriaSelectCell = ({ id, onCategorization, selectedRows, lancamentoId, tipo }) => {
  const { changeCategory, isCategoryChangeError, changedCategory, resetChangeCategory } =
    useChangeLancamentoCategory()

  useEffect(() => {
    if (isCategoryChangeError)
      noticeError(new Error('Error changing category'), {
        orcamentoId: id,
        lancamentoId,
        tipo
      })
  }, [isCategoryChangeError, id, lancamentoId, tipo])

  useEffect(() => {
    if (changedCategory) resetChangeCategory()
  }, [changedCategory, resetChangeCategory])

  const CategoriaField = useCallback(() => {
    return (
      <SelectContainer>
        <CategoriaSelect
          lancamentoId={lancamentoId}
          onSelect={(lancamentoId, _orcamentoId, idCategoria) => {
            changeCategory({ id: lancamentoId, idCategoria })
            selectedRows.forEach(({ id }) => changeCategory({ id, idCategoria }))
            onCategorization()
          }}
        />
      </SelectContainer>
    )
  }, [changeCategory, lancamentoId, onCategorization, selectedRows])

  return <CategoriaField />
}

export const CategoriaCellMemoized = ({
  id,
  categoriaPadrao,
  onCategorization,
  selectedRows,
  lancamentoId,
  tipo,
  orcamentoId
}) => {
  const { changeCategory, isCategoryChangeError, changedCategory, resetChangeCategory } =
    useChangeLancamentoCategory()

  useEffect(() => {
    if (isCategoryChangeError)
      noticeError(new Error('Error changing category'), {
        orcamentoId: id,
        lancamentoId,
        tipo
      })
  }, [isCategoryChangeError, id, lancamentoId, tipo])

  useEffect(() => {
    if (changedCategory) resetChangeCategory()
  }, [changedCategory, resetChangeCategory])

  const CategoriaField = useCallback(() => {
    console.log({ lancamentoId })
    return (
      <SelectContainer>
        <CategoriaSelect
          lancamentoId={lancamentoId}
          orcamentoId={orcamentoId}
          onSelect={(lancamentoId, _orcamentoId, idCategoria) => {
            changeCategory({ id: lancamentoId, idCategoria })
            selectedRows.forEach(({ id }) => changeCategory({ id, idCategoria }))
            onCategorization()
          }}
        />
      </SelectContainer>
    )
  }, [changeCategory, lancamentoId, onCategorization, selectedRows, orcamentoId])

  if (categoriaPadrao && !id) {
    return <CategoriaField />
  }

  return <CategoriaCell selectedId={id} />
}

export default CategoriaCell
